export const turkishLangData = {
  MEDICAL_INFO_SYSTEM: 'Tıbbi Bilgi Sistemi',
  ACTIVE_EVENTS: 'Aktif Etkinlikler',
  SELECT_AN_EVENT_TO_BROWSE_USERS: 'Kullanıcılara göz atmak için bir etkinlik seçin.',
  BACK_TO_THE_EVENT_SELECTION: 'Olay seçimine geri dön',
  IF_YOU_FORGOT_YOUR_PASSWORD: 'Şifrenizi unuttuysanız',
  LOGIN: 'Oturum aç',
  RESET_IT_HERE: 'buradan sıfırlayın',
  CHANGE_LANGUAGE_TO: 'Dili Değiştir',
  TEST_SYSTEM: 'DENEME SİSTEMİ',
  HELP: 'Yardım',
  THIS_IS_TEST_ENV: 'Bu bir test ortamıdır, gerçek soruşturma eklemek istiyorsanız',
  USER_OR_PASS_IS_INCORRECT: 'Kullanıcı adı veya şifre yanlış',
  OPS: 'Ops',
  WE_DID_NOT_FIND: 'Aradığınızı bulamadık',
  VIDEO_TUTORIALS: 'Video Eğiticileri',
  STILL_DIDNT_FIND: 'Hala aradığınızı bulamadınız,',
  ASK_THE_SUPPORT_TEAM: 'Destek ekibine sorun',
  LOGGED_IN_AS: 'olarak giriş yaptı',
  MY_PROFILE: 'Benim profilim',
  LOGOUT: 'Çıkış Yap',
  RETRIEVING_YOUR_ACCESS_INFO: 'Erişim bilgilerinizi alma',
  LOGGED_IN_CAN_CLOSE: 'Giriş yaptınız, artık bu sekmeyi kapatabilirsiniz',
  TOKEN_HAS_NOT_BEEN_SAVED: 'Jeton doğru şekilde kaydedilmedi, lütfen destekle iletişime geçin',
  PASSWORD_RESET: 'Parola sıfırlama',
  RESET_PASSWORD: 'Şifreyi yenile',
  INSERT_YOUR_EMAIL_TO_RESET_PASS: 'Şifrenizi sıfırlamak için lütfen e-postanızı girin',
  CANCEL: 'İptal etmek',
  CANCEL_UPPERCASE: 'İPTAL ETMEK',
  RETURN_TO_LOGIN: 'Giriş Sayfasına Dön',
  USERNAME: 'Kullanıcı adı',
  USERNAME_IS_REQUIRED: 'Kullanıcı adı gerekli',
  PASSWORD: 'Parola',
  PASSWORD_IS_REQUIRED: 'Şifre gereklidir',
  FIRST_NAME: 'İsim',
  LAST_NAME: 'Soyadı',
  REGISTER: 'Kayıt ol',
  LOADING: 'Yükleniyor',
  INSERT_NEW_PASS: 'Lütfen yeni şifrenizi girin',
  VERIFYING_TOKEN: 'Jetonu Doğrulama',
  PASSWORD_DO_NOT_MATCH: 'Parolalar uyuşmuyor',
  PASS_RESET_SUCCESSFUL: 'Parola Sıfırlama Başarılı',
  SAVE_NEW_PASS: 'Yeni Parolayı Kaydet',
  GO_TO_INBOX: 'Gelen Kutusuna Git',
  SELECT_ALL: 'Hepsini seç',
  DESELECT_ALL: 'Hiçbirini seçme',
  DO_NOT_SHOW_AGAIN: 'Tekrar gösterme',
  INQUIRY: 'soruşturma',
  ATTACHMENT_WITH_THIS_INQUIRY: '> Bu Sorgu ile Ekler',
  USER_DETAILS: 'Kullanıcı detayları',
  CLOSE: 'Kapat',
  TEAMS: 'Takımlar',
  TEAM: 'Takım',
  SPECIALIZATION_IS: 'Uzmanlık',
  REQUIRED: 'gereklidir',
  RESULTS_PER_PAGE: 'sayfa başına sonuç',
  RESULTS_FOUND: 'Sonuçlar bulundu',
  EXPORT: 'ihracat',
  PERSONAL_SETTINGS: 'Kişisel ayarlar',
  TEAM_SETTINGS: 'Takım Ayarları',
  USER_SETTINGS_OF: 'KULLANICI AYARLARI',
  SET_TEMPLATE: 'Şablonu Ayarla',
  NOTIFICATIONS_WILL_BE_OVERWRITTEN: 'Mevcut olanlarla bildirimlerin üzerine yazılır',
  PREVIEW: 'Ön izleme',
  NOTIFICATIONS: 'BİLDİRİMLER',
  ROLE: 'ROLÜ',
  TEMPLATE_NAME: 'ŞABLON ADI',
  SAVE_THIS_USER_AS_A_TEMPLATE: 'Bu Kullanıcıyı Şablon Olarak Kaydet',
  ADD_A_TEMPLATE_TO_YOUR_USER:
    'Kullanıcılarınıza şablon ekleme ve rol ve bildirim tercihlerini kopyalama',
  AUTH_AND_NOTIFY_TEMPLATE: 'Yetkilendirme ve Bildirimler Şablonu',
  MY_ROLES: 'Rollerim',
  STATUS: 'DURUM',
  THIS_ROLE_IS_TEMP:
    'Bu rol geçicidir, bu kullanıcının bir milletvekili olması nedeniyle verilmiştir.',
  CREATED: 'OLUŞTURULDU',
  APPROVED_REJECTED: '/ ONAYLI REDDEDİLDİ',
  APPROVED_REJECTED_BY: 'ONAYLADI / Reddedildi',
  ARCHIVE: 'ARŞİV',
  SAVE: 'Kayıt etmek',
  IMMEDIATE_EFFECT: 'Ani etki',
  OUT_OF_OFFICE_DEPUTY: 'OFİS DIŞI',
  AUTO_ASSIGN_TO: 'BU MÜKEMMEL',
  GEOGRAPHY: 'COĞRAFYA',
  ALIGNMENT: 'UYUM',
  Alignments: 'hizalamalar',
  Address: 'Adres',
  PHONE_FAX: 'Telefon / Faks',
  Email: 'E-posta',
  Contacts: 'İletişim',
  WORK_INFO: 'İş Bilgileri',
  LAST_NAME_IS: 'Soyadı',
  PERSONAL_INFO: 'Kişisel bilgi',
  USER: 'kullanıcı',
  ID: 'ID',
  UPDATED_FROM: 'güncellendi',
  ON: 'üzerinde',
  CRM: 'CRM',
  IMPORTED_FROM: '-den ithal edildi',
  CHANGE_PASS: 'Şifre değiştir',
  NOTIFICATION_IN_TEMPLATE: 'Şablondaki Bildirimler',
  CONTINUE: 'Devam et',
  DONT_SHOW_AGAIN: 'Bu mesajı bir daha gösterme',
  USING_THE_TABS_ON_THE: 'ekranın üst kısmındaki sekmeleri kullanarak',
  PERSONAL_PROFILE_SETTINGS: 'Kişisel Profil Ayarları',
  AND_YOUR: 've senin',
  YOU_CAN_SWITCH_BETWEEN: 'Arasında geçiş yapabilirsiniz',
  WELCOME_TO_YOUR_USER_PROFILE: 'Kullanıcı Profilinize Hoşgeldiniz',
  SAVE_THIS_USER_AS: 'Bu Kullanıcıyı Şablon Olarak Kaydet',
  INCLUDE_NOTIFY_SETTINGS: 'Bildirim Ayarlarını içerir',
  INCLUDE_ROLES_SETTINGS: 'Roller Ayarlarını içerir',
  ACTIVE_NOTIFICATIONS: 'AKTİF BİLDİRİMLER',
  SET_THIS_USER_AS_A_: 'Bu Kullanıcı Yetkilendirmesini ve Bildirimlerini Yeni Şablon Olarak Ayarla',
  REQUEST_AUTH: 'Yetkilendirme İste',
  REQUEST: 'İstek',
  FEEDBACK_SUPPORT: 'Geribildirim ve Destek',
  IF_YOU_STILL_CANNOT_FIND_WHAT_:
    'Aradığınızı hala bulamıyorsanız destek ekibine sorun! Ayrıca gelecekteki iyileştirmeler için bir öneriniz varsa, bu doğru yerdir.',
  TEMPLATE: 'şablon',
  SIGNATURE: 'İmzalar',
  Disclaimers: 'Feragatler',
  Greetings: 'Selamlar',
  CUSTOMIZE: 'ÖZELLEŞTİRMEK',
  SAVE_UPPERCASE: 'KAYIT ETMEK',
  DELETE: 'SİL',
  RESET_TO_DEFAULT: 'VARSAYILANA SIFIRLA',
  AFTER_SIGNATURE: 'İmza Sonrası',
  BEFORE_SIGNATURE: 'İmzadan Önce',
  AFTER_ANSWER: 'Yanıttan Sonra',
  BEFORE_ANSWER: 'Yanıtlamadan Önce',
  AFTER_GREET: 'Selamlar Sonrası',
  BEFORE_GREET: 'Selamlar Öncesi',
  Any: 'Hiç',
  HCP: 'HCP',
  NO_HCP: 'HCP yok',
  Position: 'Durum',
  ON_LABEL: 'On label',
  OFF_LABEL: 'Off label',
  YOU_ARE_TRYING_TO_CUSTOMIZE: 'Şablonu özelleştirmeye çalışıyorsunuz',
  YOU_DO_NOT_HAVE_THE_PERMISSON:
    'Bunu yapmak için izniniz yok. Devam etmek isterseniz, şablonun bir kopyası oluşturulur ve değişiklikleri burada yaparsınız.',
  CREATE_COPY: 'Kopya Oluştur',
  RECEIVED_ON: 'Alındığı Tarih',
  SENDER_EMAIL: 'E-posta Gönderme',
  SUBJECT: 'konu',
  UNSPAM: 'Unspam',
  UPLOAD: 'Yükleme',
  APPROVED: 'Onaylandı',
  DRAFT: 'taslak',
  SELECT_STATUS: 'Durum Seçin',
  UPLOAD_AS: 'Farklı Yükle',
  TITLE: 'Başlık',
  REPOSITORY: 'depo',
  BROWSE: 'Araştır',
  URL: 'URL',
  APPROVED_ON: 'Tarihinde onaylandı',
  AUTH_HOLDER: 'Yetkilendirme sahibi',
  AUTH_NUMBERS: 'Yetkilendirme numaraları',
  APPROVED_FOR: 'Için onaylandı',
  SELECT_ONE_REGION: 'Bir Bölge Seçin',
  OWNED_BY: 'Tarafından sahip olunan',
  KEYWORDS: 'Anahtar kelimeler',
  TOPICS: 'Başlıklar',
  CATEGORIES: 'Kategoriler',
  PRODUCTS: 'Ürün:% s',
  PRODUCT: 'Ürün',
  LANGUAGE_IS_REQUIRED: 'Dil gerekli',
  LANGUAGE: 'Dil',
  TARGET_CUSTOMERS: 'Hedef müşteriler',
  TYPE_IS_REQUIRED: 'Tür gerekli',
  TYPE: 'tip',
  TITLE_IS_REQUIRED: 'Başlık gerekli',
  DEFAULT_REFERENCE_IS_CREATED_:
    'Varsayılan referans Vancouver stiliyle oluşturulur. Bunu geçersiz kılmak istiyorsanız referansın tamamını buraya girin',
  REFERENCE: 'Referans',
  DOI: 'DOI',
  PUBMED_ID: 'Yayınlanmış Kimlik',
  PAGES: 'Sayfalar',
  ISSUE: 'Konu',
  VOLUME: 'Ses',
  JOURNAL: 'dergi',
  AUTHORS: 'Yazarlar',
  ABSTRACT: 'Öz',
  REFERENCE_IS_REQUIRED: 'Referans gerekli',
  THIS_DOC_IS_APPROVED: 'Bu belge onaylandı. Açmak için burayı tıklayın',
  ORIGINAL_DOCUMENT_AND_: 'orijinal belge ve yorumlarınızı ekleyin',
  EDIT_FILE: 'Dosyayı düzenle',
  PROPERTIES: 'Özellikleri',
  OPEN: 'Açık',
  LOCALIZED_DOCUMENTS: 'Yerelleştirilmiş belgeler',
  ORIGINAL_DOCUMENTS: 'Orijinal belge',
  INTERNAL_APPROVAL_NUMBER: 'Dahili onay numarası',
  CREATED_BY: 'Tarafından yaratıldı',
  EXPIRATION_DATE: 'Son kullanma tarihi',
  VERSION_NUMBER: 'Versiyon numarası',
  NO_VERSION_AVAILABLE: 'Sürüm Yok',
  ARCHIVED_IF_NO_DRAFT: 'Arşivlendi: Taslak / Onaylanmış sürüm yoksa.',
  APPROVED_FOR_USERS: 'Onaylandı: kullanıcılar için.',
  DRAFT_FOR_AUTHORS_: 'Taslak: yazarlar ve editörler için.',
  VERSION_LEGEND: 'Sürüm açıklaması',
  VERSION: 'versiyon',
  SELECT_ONE_LANG: 'Bir Dil seçin.',
  SELECT_ONE_TYPE: 'Bir Tür seçin.',
  INTERNAL_NUMBER_REQUIRED: 'Dahili onay numarası gerekiyor.',
  FILE: 'Dosya',
  ATTACH_FILE: 'Dosya eki',
  LITERATURE: 'Edebiyat',
  SLIDE_DECK: 'Kaygan güverte',
  PIL: 'PIL',
  FAQ: 'SSS',
  SRD: 'SRD',
  ADD_APPROVAL_STEP: 'Onay adımı ekle',
  ADD_PARALLEL_STEP: 'Paralel adım ekle',
  DELETE_STEP: 'Adımı sil',
  DELETE_ALL_PARALLEL_STEPS: 'Tüm paralel adımları sil',
  CHANGE_STATUS_NAME: 'Durum Adını Değiştir',
  SET_REQUIRED_ROLE: 'Gereken rolü belirle',
  SET_TEAM_MEMBER: 'Ekip üyesini ayarla',
  YOU_DO_NOT_HAVE_THE_PERMISSION_TO_: 'Bu işlemi değiştirme izniniz yok.',
  SAVE_ROLE: 'ROLÜ KAYDET',
  CREATE_NEW_WORKFLOW: 'YENİ İŞ AKIŞI OLUŞTUR',
  CREATE_NEW_STEP: 'YENİ ADIM OLUŞTUR',
  PLEASE_ENTER_THE_NUMBER_OF_:
    'Lütfen paralel onay adımlarının sayısını girin, yani aynı anda onaylanan adımlar (sırayla değil). Paralel onay adımınız yoksa 1 girin. Onay adımlarının sayısını daha sonra istediğiniz zaman değiştirebilirsiniz.',
  APPROVAL_MUST_BE_AT_LEAST: 'Onay en az olmalıdır',
  INSERT_THE_NEW_STATUS_NAME: 'Yeni durum adını girin',
  INSERT_THE_NEW_WORKFLOW_NAME: 'Yeni iş akışı adını girin.',
  INSERT_THE_NEW_STEP_NAME: 'Yeni adım adını girin.',
  NEW_DOCUMENT: 'Yeni belge',
  CREATE: 'Oluşturmak',
  DOCUMENT_MANAGEMENT: 'Doküman yönetimi',
  CONFIRM: 'Onaylamak',
  CONFIRM_PROCESS: 'SÜRECİ ONAYLA',
  DEFINE_APPROVAL_STEPS_AND_TEAM: 'TANIMLAMA ONAYLARI VE EKİBİ',
  SET_DETAILS: 'DETAYLARI AYARLA',
  SELECT_TYPE: 'TÜRÜ SEÇ',
  COMPLETE_LOCALIZATION_PROCESS: 'Tam Yerelleştirme Süreci',
  CREATE_NEW_VERSION: 'Yeni Sürüm Oluştur',
  CREATE_NEW_DOCUMENT: 'Yeni Belge Oluştur',
  IS_PRIVATE: 'Özel mi',
  SHARED_WITH_OTHER_TEAMS: 'Diğer ekiplerle paylaşıldı',
  PLEASE_ENTER_THE_NUMBER_IF_SEQ_:
    'Lütfen gerekli ardışık onay adımlarının sayısını girin. İki onay paralelse, bir adım olarak sayılır. Onay adımlarının sayısını daha sonra istediğiniz zaman değiştirebilirsiniz.',
  DONE: 'Bitti',
  SUBMIT_FOR_APPROVAL: 'Onay İçin Gönder',
  YOU_HAVE_TO_CONFIRM_THE_: 'Yüklemeden önce işlemi onaylamanız gerekir.',
  SELECT_YOUR_DEC_TYPE: 'Belge türünüzü seçin',
  TARGET_AUDIENCE: 'Hedef kitle',
  PROCESS_DETAILS: 'İşlem Ayrıntıları',
  START_DATE: 'Başlangıç tarihi',
  DOC_DETAILS: 'Belge Ayrıntıları',
  CONFIRMATION: 'Onayla',
  APPROVAL_PROCESS: 'Onay süreci',
  SCHEDULED_EXP_DATE: 'Planlanan Son Kullanma Tarihi',
  SCHEDULED_PUBLISHING_DATE: 'Planlanan Yayın Tarihi',
  NO_PROCESS_SELECTED: 'İşlem Seçilmedi',
  COUNTRIES: 'Ülkeler',
  DOC_ID: 'Belge Kimliği',
  THERAPEUTIC_AREA: 'Tedavi Alanı',
  FILTER: 'filtre',
  BACK: 'geri',
  ATTACH: 'iliştirmek',
  COPY_ANSWER: 'Cevabı Kopyala',
  SIMILAR_INQUIRIES: 'Benzer Sorular',
  SDR_FAQ: 'SRD / SSS',
  SLIDE_DECKS: 'Slayt Desteleri',
  MEDICAL_DOC: 'Tıbbi Doküman',
  SHOW: 'Göstermek',
  LOAD_MORE: 'Daha fazla yükle',
  NO_RESULT_FOUND: 'Sonuç bulunamadı.',
  CAN_BE_USED_TO_SEARCH_FOR_AN_:
    'tüm bir cümleyi aramak için kullanılabilir, - belirtilen kelimedeki arama sonuçlarından hariç tutmak için kullanılabilir',
  SEARCH_FOR_MEDICAL_SIMILAR_INQ: 'Tıbbi Belgeleri veya benzer soruları arayın',
  SEARCH: 'ARAMA',
  Preview: 'Ön izleme',
  PREVIEW_AND_SEND: 'Önizleme ve gönderme',
  PREVIEW_AS_PDF: 'PDF olarak önizleme',
  PUBLISHED_VERSION: 'Yayınlanan Sürüm',
  NEW_VERSION: 'Yeni sürüm',
  THE_DOC_IS_STILL_IN_APPROVAL_:
    'Belge içeriğini değiştirmek istiyorsanız, belge hala onaydadır, başka bir dosya yükleyin.',
  OUT_TO: 'dışarı',
  NEXT: 'Sonraki',
  SELECTED_SLIDES: 'Seçilen Slaytlar',
  GO_TO_SLIDE: 'Slayda Git',
  PREV: 'önceki',
  SELECT_SLIDE: 'Slayt Seçin',
  ANSWER: 'Cevap',
  BACK_TO_STANDARD_VIEW: 'Standart görünüme geri dönün.',
  UPLOAD_NEW_FILE: 'Yeni Dosya Yükle',
  CREATE_FILE: 'DOSYA OLUŞTUR',
  EDITING_WORD_DOC: 'Word Belgesini Düzenleme',
  INSERT_FILE_NAME: 'Dosya adı ekle',
  PROCEED_WITH_THE_LINK_: 'Dosyanın bağlantısı ile devam edin',
  A_NEW_WINDOW_WILL_BE_:
    'Boş bir dosya ile yeni bir pencere açılacak, içeriği yazacak ve sekmeyi kapatacak',
  INSERT_THE_NAME_FOR_: 'Oluşturmak istediğiniz ekin adını girin ve dosya oluşturmaya devam edin',
  INSTRUCTIONS: 'Talimatlar',
  CREATE_NEW_WORD_FILE_FOR: 'İçin yeni kelime dosyası oluştur',
  FROM: 'itibaren',
  YOU_CAN_FIND_HERE_THE_LAST_INQ_:
    'Sisteme girdiğiniz ve henüz göndermediğiniz son soruları burada bulabilirsiniz. Daha benzer sorular oluşturmak için eklediğiniz soruları tekrar kullanabilir veya eklemeyi tamamlamadığınız sorulara geri dönebilirsiniz.',
  MY_DRAFT_INQ: 'Taslak Sorularım',
  WARNING_CHANGES_NOT_SAVED: 'Uyarı: Değişiklikler kaydedilmedi',
  PAGE_THAT_WILL_BE_LOST: 'devam etmeye karar verirseniz kaybolur.',
  YOU_HAVE_UNUSED: 'Kaydedilmemiş değişiklikleriniz var',
  ARE_YOU_SURE_YOU_WANT_TO_: 'Bu sayfayı kaydetmeden bırakmak istediğinizden emin misiniz?',
  LEAVE_THIS_PAGE: 'Bu sayfadan ayrıl',
  STAY_ON: 'Devam et',
  ASSIGNED_TO: 'Atandı',
  THIS_INQ_HAS_BEEND_INSERTED_: 'Bu sorgu olay sırasında eklendi.',
  STATUS_IN_APPROVAL: 'Durum: Onaylı',
  STATUS_DRAFT: 'Durum: Taslak',
  IF_YOU_WANT_CREATE_THE_ACTUAL_INQ_:
    'Gerçek sorguyu oluşturmak istiyorsanız, gönder sekmesine gidin ve oluştur düğmesine tıklayın.',
  INQ_IMPORTED_FROM: 'Sorgu içe aktarıldı',
  SEND_NEW_ANSWER: 'YENİ CEVAP GÖNDER',
  RETURN_TO_INBOX: 'Gelen Kutusuna Dönüş',
  SUBMITTED_FOR_APPROVAL: 'Onay için sunuldu',
  ANSWER_SUBMITTED_ON: 'Yanıt şu tarihte gönderildi: ',
  BY: 'tarafından',
  INQ_HAS_BEEN_CLOSED: 'Sorgulama cevapsız kapatıldı.',
  BCC: 'Gizli',
  ANSWER_GIVEN_ON: 'Tarihinde verilen cevap',
  ANSWER_SENT_ON: 'Yanıt gönderildi',
  INBOX: 'Gelen kutusu',
  OPEN_ORIGINAL_INQ: 'Orijinal sorguyu aç',
  CLOSE_ANSWER_NOT_NEEDED: "Kapat: Anser'a gerek yok",
  HISTORY: 'Tarih',
  ADD_A_NOTE: 'Bir not ekle',
  ADD_A_NOTE_INTO_: 'Sorgu geçmişine bir not ekleyin.',
  SHARE_OR_START_A_FOLLOW_UP: 'Takip paylaşma veya başlatma',
  SEE_ALL_INQ_RELATED_TO: 'İle ilgili tüm soruları gör',
  SRD_NEEDED: 'SRD Gerekli',
  ANSWER_UNAVAILABLE: 'Yanıt Kullanılamıyor',
  QUESTION: 'Soru',
  SUBMIT: 'SUNMAK',
  ANSWER_UPPERCASE: 'CEVAP',
  DETAILS: 'DETAYLAR',
  SPLIT_INQ: 'Bölünmüş Sorgulama',
  ADD_QUESTION: 'Soru Ekle',
  ENTER_QUESTION: 'Soru Girin',
  MARK_AS_ANSWERED: 'Yanıtlandı olarak işaretle',
  TO_BE_ANSWERED: 'Yanıtlanacak',
  FOLLOW_UP_RECEIVED: 'Takip alındı',
  ERROR: 'Hata',
  SHOW_ORIGINAL_MESSAGE: 'Orijinal mesajı göster',
  SEND: 'Gönder',
  DO_NO_SEND: 'Gönderme',
  OK: 'Tamam',
  MAX_LENGTH_FOR_ZIP_CODE: 'Posta Kodu için Maksimum Uzunluk: ',
  CHARACTERS: 'karakterler',
  ADDRESS_1_IS_: 'Adres 1 (şimdiki değeri)',
  LABEL_IS: 'Etiket (şimdiki değeri)',
  NO_QUESTION_INSERTED: 'Soru eklenmedi',
  WARNING: 'UYARI',
  FOLLOW_UP_IS_NOT_AVAILABLE_: 'Bu etkileşim için takip mevcut değildir.',
  INSERT_INQ_TEXT: 'Sorgu metni ekle',
  SHOW_MORE: 'Daha fazla göster',
  OUT_OF: 'dışında',
  INQ_TO_BE_SENT: 'Gönderilecek sorular',
  HIDE_INQ: 'Soruları Gizle',
  SHARE: 'Paylaş',
  HCP_PROFILE_OF: 'HCP PROFİLİ',
  INQUIRER_DOES_NOT_HAVE_AN_EMAIL:
    'Aşağıdaki tüm zorunlu alanları ve en az bir e-posta adresini doldurun ve ardından GDPR makbuz bildirimini önizlemek için kaydedin.',
  CONFIRMATION_SENT_BY: 'Tarafından gönderilen bildirim',
  COUNTRY_IS: 'Ülke',
  COUNTRY: 'ülke',
  AT_LEAST_ONE_CONTACT_IS_: 'En az bir kişi',
  EMAIL_IS: 'E-posta: ',
  EMAIL_LABEL_IS: 'E-posta etiketi: ',
  PHONE_FAX_IS: 'Telefon / Faks etiketi: ',
  PHONE_FAX_NUMBER_IS: 'Telefon / Faks numarası (şimdiki değeri)',
  ADDRESS: 'Adres',
  DELETE_PERSONAL_DATA: 'Kişisel Verileri Sil',
  HCP_VERIFIED: 'HCP doğrulandı',
  KEY_OPINION_LEADER: 'Kilit görüş lideri',
  HOSPITAL_BASED: 'Hastane Temelli',
  HCP_RELATED_INFORMATION: 'HCP İLGILI BILGILER',
  ACCEPT: 'Kabul etmek',
  REJECT: 'reddetmek',
  DO_YOU_WANT_TO_ACCEPT_THESE_Q: 'Bu önerileri kabul etmek ister misiniz?',
  SALES_REP: 'SATIŞ REP',
  SUGGEST_A_TOPIC: 'Bir konu öner',
  TEAM_SPECIFIC_FIELDS: 'TAKIM ÖZEL ALANLARI',
  ADR_RELATED: 'ADR ile İlgili',
  NOT_ADR_RELATED: 'ADR ile İlgili Değil',
  PLEASE_REVIEW_THE_MESSAGE_AND_:
    'Lütfen mesajı gözden geçirin ve Ters İlaç Reaksiyonu ile ilgili ya da değil olarak sınıflandırın.',
  ADVERSE_DRUG_REACTION: 'Olumsuz İlaç Reaksiyonu',
  CHANGE_DETAILS: 'DETAYLARI DEĞİŞTİR',
  YES: 'Evet',
  NO: 'Hayır',
  DUE_DATE: 'Bitiş tarihi',
  CRITICAL: 'kritik',
  NORMAL: 'Normal',
  PRIORITY: 'öncelik',
  SELECT_DETAILS: 'DETAYLARI SEÇ',
  SAVE_REVIEW: 'Yorum Kaydet',
  SET_TO_ALL_INQ: 'TÜM SORULARA YAZIN',
  PRODUCT_QUALITY_COMPLAINT: 'Ürün kalitesi şikayeti',
  PQC_PC_A_PRODUCT_:
    'PQC / PC A Ürün Kalite Şikayeti (QC) veya problemi, herhangi bir ilaç ürününün kusurunu veya arızalanmasını içeren veya herhangi bir ilaç ürününün kalitesi, performansı, güvenliği ile ilgili herhangi bir endişe olarak tanımlanır. kokusu ve / veya tadı değişti, zayıf veya arızalı ambalaj, şüpheli kirlenme vb.',
  OFF_LABEL_USE_OF_PHARMA___:
    'Onaylanmamış bir endikasyon için veya onaylanmamış bir yaş grubunda, dozajda veya uygulama yolunda farmasötik ilaçların etiket dışı kullanımı.',
  A_RESPONSE_TO_A_MEDICINAL_:
    'Bir tıbbi ürün ile olumsuz bir olay arasında zararlı ve istenmeyen ve nedensel bir ilişki olan bir tıbbi ürüne yanıt en azından makul bir olasılıktır. Ayrıca özel durum vakalarının (örn. Hamilelik vakaları, etiket dışı kullanım, aşırı doz) ADR olarak işaretlenmesi gerekir.',
  NORMAL_IS_7_WORKING_DAYS: 'Normal 7 iş günü, Yüksek 48 saat ve Kritik (hastayla ilgili) 24 saat',
  CATEGORY_AND_TOPIC_ARE: 'Kategori ve konu: ',
  PRODUCT_IS: 'Ürün',
  DO_YOU_WANT_TO_ACCEPT_: 'Bu önerileri kabul etmek ister misiniz?',
  MERGE: 'go',
  Duplicate: 'Çiftleme',
  Other: 'Diğer',
  Spam: 'İstenmeyen e',
  CLOSE_INTERACTION: 'Yakın etkileşim',
  RE_OPEN_THE_TARGET_: 'Birleştirmeden sonra hedef sorguyu yeniden açın',
  MARK_AS_INTERNAL_: 'Mevcut sorgu için dahili mesaj olarak işaretleyin.',
  MARK_AS_AN_ERROR_: 'Mevcut sorgu için bir hata mesajı olarak işaretleyin.',
  THIS_MESSAGE_WILL_BE_EXISTING_:
    'Bu mesaj mevcut bir soruşturmanın takip yanıtı olarak işaretlenecektir.',
  THANK_YOU: 'teşekkür ederim',
  AN_INTERNAL_MESSAGE: 'Dahili mesaj',
  AN_ERROR_MESSAGE: 'Bir hata mesajı',
  NEW_INQ: 'Yeni soruşturma',
  A_FOLLOW_UP_RESPONSE: 'Takip yanıtı',
  THIS_INQ_IS: 'Bu soruşturma',
  SPLIT_INTERACTION: 'Ayrık Etkileşim',
  SAVE_AND_CLOSE: 'Kaydet ve kapat',
  SAVE_INQ: 'Sorguyu Kaydet',
  REVIEW_THE_TEXT_AND: 'Metni ve kategorileri inceleyin ve onaylayın',
  CHOOSE_THE_RELEVANT_PRODUCT: 'Seçilen metin için ilgili ürünü, kategoriyi ve konuyu seçin',
  SELECT_THE_TEXT_THAT_:
    'Bir ürün / konu hakkındaki soruyu tanımlayan metni seçin (selamlar, imzalar, feragatnameler veya alakalı olmayan diğer bilgileri dahil etmeyin)',
  REPEAT_THE_PREVIOUS_: 'Daha fazla soru eklemek için önceki adımları tekrarlayın',
  READ_MORE: 'Daha fazla oku',
  READ_LESS: 'Az oku',
  DO_NOT_INCLUDE_:
    'selamlar, imzalar, feragatnameler veya diğer alakalı olmayan bilgileri dahil etmeyin',
  SELECT_THE_TEXT_THAT_IDENT: 'Bir ürün / konu hakkındaki soruyu tanımlayan metni seçin',
  IF_YOU_HAVE_MULTIPLE_:
    'Birden fazla ürününüz veya başlığınız varsa, soruşturmanın bölünmesi gerekir.',
  THE_FOLLOWING_PROCESS_:
    'Aşağıdaki işlem, her ürün / konu için ilgili metni belirlemenize yardımcı olacaktır: ',
  EXPORT_TO_PDF: 'PDF İHRACATI',
  Added: 'Katma',
  Deleted: 'silindi',
  From: 'itibaren',
  To: 'için',
  BACK_TO_INBOX: 'Gelen kutusuna geri dön',
  PERMISSION_DENIED: 'İzin reddedildi',
  THIS_INQ_IS_LOCKED_BY: 'Bu sorgu tarafından kilitlendi',
  FORCE_UNLOCK: 'KUVVET KİLİDİNİ AÇ',
  INQUIRIES: 'Araştırma',
  Download: 'İndir',
  Event: 'Etkinlik',
  INSERTED_BY: 'Ekleyen',
  LAST_EDITED_ON: 'Son Düzenleme Tarihi',
  LAST_EDITED_BY: 'Son Düzenleyen',
  CREATED_ON: 'Oluşturma Tarihi',
  TOPIC: 'konu',
  TABLE_OPTIONS: 'Tablo Seçenekleri',
  DEPARTMENT: 'Bölüm',
  User: 'kullanıcı',
  ADD_THERAPEUTIC_AREA: 'Terapötik Alan Ekle',
  CREATE_THERAPEUTIC_AREA: 'Yeni terapötik alan oluşturun',
  ADD_NEW: 'Yeni ekle',
  SELECT_TEAM_TO_PROCEED: "Devam etmek için Takım'ı seçin",
  MIM: 'MIM',
  MA: 'MA',
  MSL: 'MSL',
  SALES: 'Satış',
  CALL_CENTER: 'Çağrı merkezi',
  ALL: 'Herşey',
  Warning: 'Uyarı',
  clear: 'açık',
  THESE_CHANGES_WILL_BE_: 'Bu değişiklikler seçilen kullanıcılara uygulanacak',
  INSERT_A_FOLLOW_UP_RESP: 'Bir Takip Yanıtı Ekle',
  INSERT_A_NOTE: 'Not ekle',
  MERGED_FROM: 'Birleştirildi',
  CHANGES: 'değişiklikler',
  SHOW_LESS: 'daha az göster',
  COMPLETE: 'Tamamlayınız',
  MISSING: 'Eksik',
  CLOSE_AS_FORWARDED: 'Yönlendirildiği gibi kapat',
  CLOSE_EXT_TEAM: 'Harici ekibe yönlendirilen sorguyu kapat',
  THE_INQ_BELONG_TO_A_:
    'Bu soruşturma harici bir ekibin sorumluluğunda olan bir ülkeye aittir. Sorgulama daha sonra otomatik olarak bu ekibe yönlendirilecektir.',
  RESOLVE: 'çözmek',
  TERRITORIES: 'Bölgeleri',
  EXTERNAL_COMMUNICATION: 'DIŞ İLETİŞİM',
  FOR_INQ_COMING_DIRECTLY_:
    "Doğrudan HCP'den e-posta yoluyla gelen sorular için, otomatik olarak soruşturmanın alındığını bildirmek için bir bildirim gönderin.",
  INTERNAL_COMMUNICATION: 'İÇSEL İLETİŞİM',
  SALESREP_RECEIVES_: 'Salesrep yalnızca soruşturmanın yanıtlandığına dair bildirimler alır',
  SALESREP_RECEIVES_FULL_ANSWER_: 'Salesrep, Etiket Üzeri Sorgulamalar için tam yanıt alır',
  SALESREP_RECEIVES_ONLY_NOTIF_:
    'Salesrep yalnızca soruşturmanın yanıtlandığına dair bildirimler alır',
  Name: 'ad',
  Module: 'modül',
  Function: 'fonksiyon',
  LAST_RUN: 'Son koşu',
  End: 'Son',
  Every: 'Her',
  Period: 'dönem',
  Active: 'Aktif',
  Resolved: 'kararlı',
  ERROR_PREVIEW: 'Hata Önizleme',
  FAILURE_COUNT: 'Arıza Sayısı',
  COMPLETED_ON: 'Tamamlandı',
  STARTED_ON: 'Başladı',
  TASK_NAME: 'Görev adı',
  PRODUCT_SPECIFIC: 'ÜRÜN ÖZEL',
  THERAPEUTIC_AREAS: 'TERAPÖTİK ALANLAR',
  PRODUCT_GROUPS: 'ÜRÜN GRUPLARI',
  LICENSING_PARTNER: 'LİSANS ORTAKLIĞI',
  PRODUCT_CODE: 'ÜRÜN KODU',
  EXTERNAL_ID: 'Harici Kimlik',
  OBJECT_TYPE: 'Nesne türü',
  MIGRATION_NAME: 'Taşıma Adı',
  MIGRATION_ERRORS: 'Taşıma Hataları',
  ADD_SALES_REP: 'Satış Temsilcisi Ekle',
  ASSIGNED_TO_MSL: "MSL'ye Atandı",
  NOT_ASSIGNED: 'Atanmadı',
  ASSIGNED_TO_OTHER_MSL: "Diğer MSL'ye atandı",
  ASSIGNED_TO_THIS_MSL: "Bu MSL'ye atandı",
  SHOW_ONLY: 'Yalnızca göster',
  SALES_REP_LIST: 'Satış Temsilcisi Listesi',
  ACTIVE_TASK: 'Görevi Etkinleştir',
  CHOOSE_USER_AND_SET_THE_SHIFT_: 'Kullanıcı seçin ve etkinlik için vardiyayı ayarlayın',
  MANAGE_SHIFTS: 'DEĞİŞİMLERİ YÖNET',
  CREATE_NEW_USER: 'Yeni kullanıcı oluştur',
  THIS_TEMPLATE_WILL_BE_AUTO_: 'Bu şablon otomatik olarak ekip üyenize eklenecektir.',
  TEMPLATE_FOR: 'İçin şablon',
  Day: 'Gün',
  Week: 'Hafta',
  Month: 'Ay',
  Today: 'Bugün',
  Archive: 'Arşiv',
  PARTICIPANTS: 'KATILIMCILAR',
  EVENT_MANAGEMENT: 'OLAY YÖNETİMİ',
  ADD_TASK: 'Görev ekle',
  EDIT_TASK: 'Görevi Düzenle',
  IS_ACTIVE: 'Aktif',
  Months: 'aylar',
  Days: 'günler',
  Hours: 'Saatler',
  Minutes: 'Dakika',
  HCP_ADMINISTRATION: 'HCP İDARESİ',
  AUTH_REQ_ADMINISTRATION: 'Yetkilendirme Talepleri Yönetim',
  THE_PRODUCT_WILL_BE_HIGHLIGHTED_IN_: 'Ürün seçilen Terapötik Alanlarda vurgulanacaktır.',
  PRODUCT_GROUPS_DEFINE_:
    'Ürün Grupları, bu ürünle ilgili soruları yanıtlamaktan sorumlu ekibi tanımlar.',
  USER_ADMINISTRATION: 'Kullanıcı Yönetimi',
  IS_PRODUCT_SPECIFIC: 'Ürüne Özgüdür',
  USER_TEMPLATES_ADMIN: 'Kullanıcı Şablonları Yönetimi',
  SET_DEFAULT_VALUES: 'VARSAYILAN DEĞERLERİ AYARLA',
  SET_DEFAULT_VALUES_FOR_: 'Etkinlik için varsayılan değerleri ayarlama',
  INQUIRY_DEFAULT_VAL: 'Sorgu varsayılan değerleri',
  CREATE_NEW_EVENT: 'YENİ OLAY OLUŞTUR',
  EDIT_EVENT: 'ETKİNLİĞİ DÜZENLE',
  SET_DETAILS_TO_CREATE_NEW_EVENT: 'Yeni etkinlik oluşturmak için ayrıntıları ayarlayın',
  ADD_DOC_LINKED_TO_: 'Etkinliğe bağlı belgeler ekleyin',
  INC_CHANNEL: 'Inc. Kanalı',
  CHANGE_DETAILS_OF_THE_EVENT: 'Etkinliğin ayrıntılarını değiştirme',
  DESCRIPTION: 'Açıklama',
  CREATE_APPROVAL_PROCESS: 'Onay Sürecini Seçin',
  CREATE_NEW: 'Yeni oluşturmak',
  CUSTOMIZE_PROCESS: 'Süreci Özelleştir',
  SELECTED_PROCESS: 'Seçilen Süreç',
  CLONE: 'Klon',
  PREFERENCES_TITLE: 'Tercihler',
  NOTIFICATIONS_TITLE: 'Bildirimler',
  TEMPLATES: 'Şablonlar',
  LETTER_TEMPLATES: 'Mektup şablonu',
  USER_TEMPLATES: 'Kullanıcı Şablonları',
  TEAM_MEMBERS: 'Takım üyeleri',
  NEW_PASSWORD: 'Yeni Şifre',
  REPEAT_NEW_PASSWORD: 'Yeni şifreyi tekrar girin',
  AT_LEAST_1_LOWERCASE_CHARACTER: 'En az 1 küçük harf',
  AT_LEAST_1_UPPERCASE_CHARACTER: 'En az 1 büyük harf',
  AT_LEAST_1_DIGIT: 'En az 1 basamak',
  AT_LEAST_1_SPECIAL_CHARACTER: 'En az 1 özel karakter',
  MINIMUM_8_CHARACTERS: 'En az 8 karakter',
  SHOULD_NOT_CONTAIN_EMAIL_OR_NAMES: 'E-posta veya isim içermemelidir',
  SHOULD_MATCH_PASSWORD: 'Şifre ile eşleşmelidir',
  USER_MANUAL: 'Kullanım kılavuzu',
  SUPPORT: 'Destek',
  BACK_TO_LOGIN: 'Giriş Sayfasına Geri Dön',
  PASSWORD_CONFIRMATION: 'Şifre onayı',
  HAVE_AN_ACCOUNT: 'Zaten bir hesabınız var mı?',
  Submit: 'Sunmak',
  Gender: 'Cinsiyet',
  PROFESSION: 'Meslek',
  MEDICAL_INQ_ROLES: 'Tıbbi Sorular İşleme Rolü',
  MEDICAL_INFO_MANAGER: 'Tıbbi Bilgi Yöneticisi',
  MEDICAL_SCIENCE_LIAISON: 'Tıp Bilimi İrtibat',
  NOT_PROCESSING_INQUIRIES: 'Sorguları İşlememe',
  ACTIVE_HEALTH_CARE_SPEC: 'Aktif Sağlık Bakım Uzmanı',
  NOT_ACTIVE_HEALTH_CARE_SPEC: 'Aktif Olmayan Sağlık Bakım Uzmanı',
  PASS_IS_REQUIRED: 'Şifre gereklidir!',
  EMAIL_IS_REQUIRED: 'Email gereklidir!',
  VALID_EMAIL: 'Geçerli bir e!',
  FIELD_IS_REQUIRED: 'Bu alan gereklidir!',
  ONLY_LETTERS: 'Lütfen yalnızca harf girin!',
  ARE_YOU_daiichi_EMPLOYEE: 'Daiichi Sankyo çalışanı mısınız?',
  here: 'buraya',
  CREATE_AN_ACCOUNT: 'Hesap oluştur',
  FORGOT: 'Unuttun?',
  Medical: 'Tıbbi',
  Medical_not_resp: "Tıbbi - MIR'lere yanıt vermekten sorumlu değildir",
  Commercial: 'Ticari',
  Market_access: 'Pazar erişimi',
  Pharmacovigilance: 'Farmakovijilans',
  PR: 'PR',
  Please_specify: 'Lütfen belirtiniz',
  Medical_information_manager: 'Tıbbi bilgi yöneticisi',
  Medical_advisor: 'Tıbbi danışman',
  Are_Medical_inquiries: 'Tıbbi soruları cevaplamaktan sorumlu musunuz?',
  Are_Health_care_professional: 'Pratik bir sağlık uzmanı mısınız?',
  Health_care_Industry: 'Sağlık Endüstrisi',
  Health_Authority: 'Sağlık Otoritesi',
  Journalist: 'Gazeteci',
  Lawyer: 'Avukat',
  Patient: 'Hasta',
  Payor: 'Payor',
  Scientist: 'Bilim insanı',
  Angiologist: 'Angiologist',
  Cardiologist: 'kardiyolog',
  Cardiovascular_Surgeon: 'Kalp ve Damar Cerrahı',
  Dentist: 'Diş doktoru',
  Gastroenterologist: 'gastroenterolog',
  General_Practitioner: 'Pratisyen',
  Geriatrician: 'yaşlılık hastalıkları uzmanı',
  Gynecologist: 'Kadın Doğum uzmanı',
  Haematologist: 'haematologist',
  Internist: 'dahiliyeci',
  Medical_Student: 'Tıp öğrencisi',
  Nephrologist: 'Nefroloji',
  Neurologist: 'Nörolog',
  Neurosurgeon: 'Beyin cerrahı',
  Nurse: 'Hemşire',
  Oncologist: 'onkolog',
  Oncology_Nurse: 'Onkoloji Hemşiresi',
  Other_HCP: 'Diğer HCP',
  Other_Surgeon: 'Diğer Cerrah',
  Pediatrician: 'çocuk doktoru',
  Pharmacist: 'eczacı',
  Pharmacologist: 'farmakolojist',
  Pharmacy_Technician: 'Eczane Teknikeri',
  Pneumologist: 'Pneumologist',
  Radiologist: 'Radyolog',
  Rheumatologist: 'romatolog',
  USER_PASS_INCORRECT: 'Kullanıcı adı veya şifre yanlış',
  SUBMITTED_REQUEST: 'Talebiniz başarıyla gönderildi.',
  Reset: 'Sıfırla',
  Reset_pass: 'Şifreyi yenile',
  New_pass: 'Yeni Şifre',
  RESET_PASS_EMAIL: 'E-postanıza bir şifre sıfırlama bağlantısı gönderildi',
  NEW_PASS_SUCCESS: 'Parolanız başarıyla sıfırlandı',
  SOMETHING_WENT_WRONG: 'Bir şeyler ters gitti, lütfen destek ekibiyle iletişime geçin.',
  EVENTS: 'Etkinlikler',
  Login_here: 'Giriş yapın',
  REGISTRATION: 'kayıt',
  Create_an_account: 'Hesap oluştur',
  NO_RECORDS: 'Kayıt yok',
  INTERACTION: 'etkileşim',
  SPECIALIZATION: 'uzmanlaşma',
  SPECIALIZATION_TYPE: 'Uzmanlık Türü',
  SAVED: 'Kayıtlı',
  SAVING: 'Tasarruf',
  TYPING: 'Yazıyor',
  CREATE_NEW_INTERACTION: 'Yeni Etkileşim Oluştur',
  FILTER_BY: 'Tarafından filtre',
  SEND_EMAIL: 'Eposta gönder',
  SAVE_WITHOUT_EMAIL: 'E-posta Olmadan Kaydet',
  SUBMIT_TO_INBOX: 'Gelen Kutusuna Gönder',
  Create_my_acc: 'Hesabımı oluşturmak',
  NEW_QUESTION: 'Yeni Soru',
  LABEL: 'Etiket',
  SELECT_A_LABEL: 'Bir etiket seçin',
  CITY: 'Kent',
  SUGGEST_A_NEW_CATEGORY_OR_SELECT_: 'Yeni bir kategori önerin veya mevcut bir kategoriyi seçin',
  SUGGEST_CATEGORY_OR_TOPIC: 'Yeni kategori veya konu öner',
  URGENT: 'Acil',
  EXISTING_CATEGORY: 'Mevcut Kategori',
  NEW_CATEGORY_NAME: 'Yeni Kategori Adı',
  NEW_TOPIC_NAME: 'Yeni Konu Adı',
  CATEGORY: 'Kategori',
  MSLA: 'MSLA',
  OUT_CHANNEL: 'Giden Kanal',
  LOGIN_TO_CONTINUE: "Madjenta'ya devam etmek için giriş yapın",
  Email_address: 'E',
  FORGOT_PASS: 'Parolanızı mı unuttunuz?',
  Remember_me: 'Beni hatırla',
  YOU_WILL_BE_NOTIFIED: 'Kaydınız için ek talimatlarla birlikte onay e-postası gönderildi',
  SUCCESS_EMAIL_CONFIRMATION: 'E-postanız başarıyla onaylandı.',
  SUCCESS_EMAIL_CONFIRMATION_SUB: 'Talebiniz incelendiğinde size bilgi verilecektir',
  FAILED_EMAIL_CONFIRMATION: 'E-postanızı onaylayamıyoruz.',
  FAILED_EMAIL_CONFIRMATION_SUB:
    'Lütfen tekrar deneyin ve sorun devam ederse sorunun açıklamasıyla destek yazmaya devam edin',
  RESET_FILTERS: 'Filtreleri Sıfırla',
  NEW_PASS_RESET_SUCCESS: 'Şifre başarıyla sıfırlandı!',
  Cardiovascular: 'Kardiyovasküler',
  Oncology: 'Onkoloji',
  Due_On: 'Bitiş Tarihi',
  Follow_Up_Sent_On: 'Takibi Gönderildi',
  Follow_Up_Received_On: 'Alınan Takip',
  Closed_On: 'Kapalı',
  Reopened_On: 'Yeniden Açıldı',
  Inquiry_ID: 'Sorgu Kimliği',
  Search: 'Arama',
  Assigned_to_Now: 'Atandı (Şimdi)',
  Edited_By: 'Tarafından düzenlendi',
  Assigned_to_Any_Time: 'Atanan (Her Zaman)',
  Closed: 'Kapalı',
  Foreign: 'Dış',
  Complex_search_Sentence_: 'Karmaşık arama: Cümle: "aramak için cümle", Not: -word',
  Search_inquiries: 'Arama soruları',
  SEARCH_REPOSITORY: 'Depoda ara',
  MIR_INBOX: 'MIR INBOX',
  Filters: 'Filtreler',
  Active_Filters: 'Aktif Filtreler',
  CONTACT_SUPPORT: 'İletişim desteği',
  MedicalInformationRequests: 'Sorular (pdf)',
  UserProfileNavigation: 'Kullanıcı Profili (pdf)',
  UserManualChanges: 'Kullanım Kılavuzu Değişiklikleri (docx)',
  iPad_How_To: 'iPad (pdf)',
  MedIS_Assign_Inquiry: 'Sorgu Ata',
  MedIS_Documentation_Approval_Process: 'Dokümantasyon Onay Süreci',
  MedIS_Documentation_Overview: 'Belgelere Genel Bakış',
  MedIS_Inquiry_Documentation: 'Sorgulama Belgeleri',
  MedIS_MIR_Inbox_Filters: 'MIR Gelen Kutusu Filtreleri',
  MedIS_Merge_MIR: 'MIR Birleştir',
  MedIS_New_Documentation: 'Yeni Belgeler',
  MedIS_New_Inquiry: 'Yeni soruşturma',
  MedIS_Out_Of_Office: 'Ofis dışında',
  MedIS_Prepare_Custom_Answer: 'Özel Yanıt Hazırla',
  MedIS_Split_MIR_Part_I: 'Split MIR Bölüm I',
  MedIS_Split_MIR_Part_II: 'Split MIR Bölüm II',
  I_AGREE_TO: 'Katılıyorum',
  TermsAndConditionsLink: 'Şartlar ve koşullar',
  Title: 'Başlık',
  INTERACTION_TITLE: 'Etkileşim Başlığı',
  Move: 'Hareket',
  Left: 'Ayrıldı',
  Right: 'Sağ',
  Requestor: 'Talep eden',
  Assigned_To: 'Atandı',
  Status: 'durum',
  Note: 'Not',
  Confirm: 'Onaylamak',
  rejectConfirm: 'İsteği reddetmek istediğinizden emin misiniz?',
  approveConfirm: 'İsteği onaylamak istediğinizden emin misiniz?',
  newUser: 'Yeni kullanıcı',
  editUser: 'kullanıcıyı düzenle',
  deleteUser: 'Kullanıcıyı sil',
  newProduct: 'Yeni ürün',
  editProduct: 'Ürünü Düzenle',
  deleteProduct: 'Ürünü Sil',
  newCategory: 'Yeni kategori',
  editCategory: 'Kategoriyi Düzenle',
  deleteCategory: 'Kategoriyi Sil',
  newTopic: 'Yeni Konu',
  editTopic: 'Konuyu Düzenle',
  deleteTopic: 'Konuyu Sil',
  userRequest: 'Kullanıcı isteği',
  productRequest: 'Ürün Talebi',
  categoryRequest: 'Kategori Talebi',
  categoryTopicRequest: 'Kategori / Konu Talebi',
  topicRequest: 'Konu Talebi',
  Suggestion: 'Öneri',
  tradeName: 'Ticari unvan',
  activeSubstance: 'Aktif Madde',
  productName: 'Ürün adı',
  productSpecific: 'Ürüne özgü mü?',
  EMAIL_IN_USE: 'Bu e-postada mevcut bir hesap var',
  fromDate: 'İtibaren',
  toDate: 'Bugüne kadar',
  applyFilter: 'Filtre Uygula',
  requestReceivedFrom: 'İstek alındı',
  on: 'üzerinde',
  at: 'en',
  to: 'için',
  add: 'Ekle',
  from: 'itibaren',
  approve: 'onaylamak',
  reject: 'reddetmek',
  withTheFollowingRole: 'aşağıdaki rolle',
  forTheFollowingTeam: 'aşağıdaki takım için',
  request: 'istek',
  submittedBy: 'tarafından sunulan',
  INVALID_OR_EXPIRED_RESET_REQUEST: 'Şifre sıfırlama isteği geçersiz veya süresi dolmuş',
  PASS_IS_RECENT: 'Şifre son zamanlarda kullanıldı',
  Assigned_to_me: 'Beni görevlendirdi',
  My_drafts: 'Taslaklarım',
  My_open_inquiries: 'Açık sorularım',
  My_closed_inquiries: 'Kapalı sorularım',
  Filter_inquiries_by: 'Soruları şuna göre filtrele',
  New_inq: 'Yeni soruşturma',
  Inq_inbox: 'Sorular Gelen Kutusu',
  REPORTS: 'Raporlar',
  My_teams_open_inq: 'Ekibimin açık soruları',
  My_teams_closed_inq: 'Ekibimin kapalı soruları',
  All_teams_open_inq: 'Tüm takımlar soru soruyor',
  All_teams_closed_inq: 'Tüm ekipler soruşturmaları kapattı',
  Pending_approval: 'Onay bekleyen',
  ADMINISTRATION: 'yönetim',
  Show_all: 'Hepsini Göster ↓',
  In_progress: 'Devam etmekte',
  Table_Settings_Columns: 'Tablo Ayarları - Sütunlar',
  Change_column_visibility: 'Sütun görünürlüğünü değiştirme',
  Reassign_to: 'Yeniden ata',
  set_priority: 'Önceliği Belirle',
  set_status: 'Durumu Ayarla',
  Spam_list: 'Spam listesi',
  Institution: 'kurum',
  DUE_ON: 'Son tarih: ',
  Profile_settings: 'Profil ayarları',
  Preferences: 'Tercihler',
  Role_settings: 'Rol Ayarları',
  Notification_settings: 'Bildirim ayarları',
  User_profile: 'Kullanıcı profili',
  Select_person: 'Kişi seçin',
  FROM_DATE: 'İtibaren',
  TO_DATE: 'Bugüne kadar',
  JOB_DESC: 'İş tanımı',
  INQ_Management: 'Sorgulama Yönetimi',
  DOCS_Management: 'Doküman yönetimi',
  USER_Management: 'Kullanıcı yönetimi',
  Define_Frequency: 'Frekansı Tanımla',
  All_teams_all_inq: 'Tüm takımlar için tüm sorular',
  My_teams_all_inq: 'Ekibim için tüm sorular',
  Search_people_here: 'Burada kişi ara',
  Search_team_here: 'Burada ekip ara',
  people: 'İNSANLAR',
  teams: 'TAKIMLAR',
  empty: '(boş)',
  can_not_be_assigned: 'Bu kullanıcının ekibi yok ve atanamaz',
  select_a_team: 'Lütfen için bir takım seçin',
  confirm_team_change: 'Takım Değişikliğini Onayla',
  change_the_team_: 'Ekibi değiştirmek istediğinizden emin misiniz?',
  can_not_see_it_anymore: 'Mevcut durumda göremezsiniz',
  filtered_view: 'filtre uygulanmış görünüm.',
  PHONE: 'Telefon',
  FAX: 'Faks',
  LETTER: 'mektup',
  F2F_SITE_VISIT: 'F2F (Saha Ziyareti)',
  F2F_CONGRESS: 'F2F (Kongre)',
  F2F_ADBOARD: 'F2F (AdBoard)',
  F2F_OTHER: 'F2F (Diğer)',
  WEBINAR_SITE_VISIT: 'Sanal (Site Ziyareti)',
  WEBINAR_CONGRESS: 'Sanal (Kongre)',
  WEBINAR_ADBOARD: 'Sanal (AdBoard)',
  WEBINAR_OTHER: 'Sanal (Diğer)',
  CHAT: 'Sohbet',
  COURIER: 'Kurye',
  REP_DELIVERY: 'Temsilci Teslimatı',
  SYSTEM: 'sistem',
  WEB_FORM: 'İnternet formu',
  Signature: 'İmza',
  Disclaimer: 'feragat',
  STATE: 'Durum',
  ADDRESS_1: 'Adres 1',
  ADDRESS_2: 'Adres 2',
  ZIP_CODE: 'Posta kodu',
  HIGH: 'Yüksek',
  ADVANCED: 'ileri',
  CLICK_BELOW_TO_POPULATE_ANSWER:
    'Cevabı doldurmak ve düzenlemek için aşağıdaki metni ve alanları tıklayın',
  DOCUMENT_VIEW: 'Belge Görünümü',
  FORM_VIEW: 'Form Görünümü',
  YOU_DONT_HAVE_RIGHTS_TO_ANSWER: 'Cevabı gönderme hakkınız yok.',
  ANSWER_TEXT: 'Cevap Metni',
  CONTACT_INFORMATION: 'İletişim bilgileri',
  COMPANY: 'şirket',
  SRD_NEEDED_TOOLTIP: 'Bu sorgunun cevabı yeni SRD belgesi gerektiriyor',
  ENABLE_ALL: 'Hepsini etkinleştir',
  DISABLE_ALL: 'Hepsini etkisiz hale getir',
  BEFORE: 'Önce',
  AFTER: 'Sonra',
  EDIT: 'Düzenle',
  MY_TEMPLATES: 'Şablonlarım',
  TEAM_TEMPLATES: 'Takım Şablonları',
  TEAM_TEMPLATES_HINT: 'Tüm ekip için Tıbbi Bilgi Yöneticisi tarafından oluşturulan şablon',
  SOURCE: 'Kaynak',
  TEMPLATE_NO_MATCH_INTERACTION: 'Bu şablon, etkileşim ayrıntılarıyla eşleşmiyor ve geçerli değil',
  EMAIL_SUBJECT: 'E-posta konu',
  INQUIRY_SUBMIT_HINT: 'Soruyu cevaplamak için lütfen tüm gerekli alanları doldurun',
  SCHEDULED_PUBLISH_TO_EXP_DATE: 'Sona Erme Tarihine Planlanmış Yayınlama',
  COPY_ATTACHMENT_TO_ANSWER: 'Eki Cevaba Kopyala',
  COPY_TEXT_TO_ANSWER: 'Metni Yanıta Kopyala',
  SELECT_INQUIRY: 'Sorgu Seçiniz',
  EDIT_INQ: 'Soruyu Düzenle',
  SPLIT_ANOTHER: 'Başka Böl',
  DELETE_INQ: 'Sorguyu Sil',
  SELECT_TEXT_FOR_SPLIT: 'Aynı etkileşimde yeni soru ekleyin',
  SELECT_TEXT_FOR_SPLIT_NEW_INTERACTION: 'Ayrı etkileşim / vaka oluşturun',
  SPLIT_ORIGINAL_TEXT: 'Orijinal Metin (Bölünecek metni seçin)',
  SPLIT_TEXT_TO_REMAIN_: 'İlk sorgulamada kalacak metin',
  SPLIT_TEXT_TO_SPLIT_: 'Bölünecek metin',
  SPLIT_SELECTED_SECTIONS: 'Bölme için seçilen bölümler',
  SPLIT_NO_MODIFICATIONS_HINT:
    'Orijinal metinden bölümleri seçerken metin otomatik olarak doldurulacaktır',
  SPLIT_MODIFICATIONS_HINT:
    'Metin manuel olarak değiştirildi ve orijinal metinden başka herhangi bir seçim uygulanmayacak.\n Değişiklikleri atmak için burayı tıklayın.',
  TEMPLATE_NO_MODIFICATIONS_HINT:
    'Metin, sorgu detaylarındaki değişikliklerle otomatik olarak güncellenecektir.',
  SELECT_DIFFERENT_TEMPLATE: 'Farklı bir şablon seçin',
  APPROVE: 'Onayla',
  LAST_EDIT_ON: 'Son Düzenleme',
  LAST_EDIT_BY: 'Son Düzenleyen',
  INTERNAL_COMM: 'İçsel iletişim',
  INACTIVE: 'Etkin değil',
  DEACTIVATE: 'Devre dışı bırakmak',
  ARCHIVED: 'Arşivlendi',
  REPUBLISH: 'Yeniden yayınla',
  INBOX_NO_FILTERS_HINT:
    'Seçili filtre yok.\n Aradığınız sorguyu bulamazsanız, doğru gelen kutusu bölümünde olup olmadığınızı kontrol edin.',
  INTERACTION_TEMPLATE_HINT:
    'İmzalar, selamlar ve sorumluluk reddi beyanları, e-posta, mektup ve faks gibi yazılı iletişimin bir parçasıdır.\n Bunları kullanmak için giden kanalın bunlardan birine ayarlandığından emin olun.',
  CONFIRM_AUTH_REQUEST: 'Yetkilendirme İsteğini Onaylayın',
  VIEW_AUTH_REQUEST: 'Yetkilendirme İsteğini Görüntüle',
  QUESTION_TITLE: 'Soru başlığı: ',
  NO_TITLE_INQUIRY: '-başlık yok-sorgulama-',
  EXISTING_CATEGORIES: 'Mevcut Kategoriler',
  EXISTING_TOPICS: 'Mevcut Konular',
  DO_NOT_ASSIGN_PRODUCT: 'Ürün atama',
  QUESTION_TEXT: 'Soru metni',
  DATE: 'Tarih',
  REJECTED: 'Reddedildi',
  FILTER_AUTH_REQUESTS_BY: 'Kimlik doğrulama isteklerini şuna göre filtrele: ',
  ALL_REQUESTS_ALL_TEAMS: 'Tüm ekipler için tüm talepler',
  ALL_TEAMS: 'Tüm takımlar',
  YOU_DONT_HAVE_RIGHTS_TO_EDIT_REQUEST: 'Kategori / Konu isteklerini düzenleme hakkınız yok.',
  SEND_FOLLOW_UP: 'Takip Gönder',
  RECEIPT_NOTIFICATION_CUSTOMER: 'Müşteriye makbuz bildirimi',
  GDPR_DISCLAIMER: 'GDPR sorumluluk reddi',
  ACTIONS: 'Hareketler',
  SUBMISSION_CONDITIONS: 'Gönderim koşulları',
  TYPE_OF_NOTIFICATION: 'Bildirim türü',
  DIRECT_SUBMISSION: 'Doğrudan gönderim',
  DIRECT_SUBMISSION_TOOLTIP:
    'Doğrudan müşteriden e-posta yoluyla gönderilen sorular için, müşteriye kişisel verilerinin işlenmesi hakkında bilgi vermek için bir bildirim gönderin.',
  COMPANY_EMPLOYEE_SUBMISSION: 'Şirket çalışanı aracılığıyla gönderildi',
  ENABLE_NOTIFICATION: 'Bildirimleri etkinleştir',
  NOTIFICATION_SENDER: 'Gönderene Bildirim',
  SALES_REP_DELIVERED_RESPONSE: 'Teslim edilen yanıt için satış temsilcisi',
  NOTIFICATION_DELIVERED_RESPONSE: 'Teslim edilen yanıt için bildirim',
  NOTIFICATION_DELIVERED_RESPONSE_TOOLTIP:
    'Şirket temsilcisi (satış temsilcisi, MSL veya diğer) aracılığıyla gönderilen sorular için, müşteri sorgusuna yanıt aldığında göndericiyi bilgilendirin',
  FULL_ANSWER: 'Tam cevap',
  NOTIFICATION: 'Bildirim',
  NAME: 'Isim',
  COLUMN_INIT_QUESTION_TEXT: 'Orijinal soru metni',
  NOTIFICATION_ENABLED: 'Bildirim etkinleştirildi',
  DEFAULT_TEMPLATE: 'Varsayılan şablon',
  SET_AS_DEFAULT: 'Varsayılan olarak ayarla',
  DEFAULT_TEMPLATE_TOOLTIP:
    'En az bir varsayılan şablon olması gerektiğinden geçiş devre dışı bırakıldı!',
  DEFAULT_TEMPLATE_TOOLTIP_DELETION_NOT_POSSIBLE: 'Varsayılan şablon silinemez!',
  BATCH_UPDATE_ONLY: 'En üstteki onay kutusu aracılığıyla yalnızca toplu güncelleme yapılabilir.',
  ACCESS_DENIED: 'Erişim reddedildi!',
  YOU_DO_NOT_HAVE_PERMISSION_TO_ACCESS_THIS_PAGE: 'Bu sayfaya erişim izniniz yok.',
  USER_ROLES: 'Kullanıcı rolleri',
  MANAGE_USER_ROLES: 'Kullanıcı rollerini yönet',
  ARCHIVE_ROLE: 'Arşiv rolü',
  ROLE_ARCHIVE_CONFIRMATION: 'Bu rolü gerçekten arşivlemek istiyor musunuz?',
  ROLES_FOR: 'için roller',
  AUDIT_TRAIL: 'Denetim izi',
  ACTION_BY_USER: 'Kullanıcıya göre işlem',
  ACTION: 'Action',
  ORIGINAL_VALUE: 'Orijinal değeri',
  CHANGE: 'Değiştirmek',
  CONDITION: 'Şart',
  IP: 'IP',
  GEOLOCATION: 'coğrafi konum',
  NEW_VALUE: 'Yeni değer',
  FILTER_BY_ACTION: 'Eyleme göre filtrele',
  SELECT_DATE: 'Tarih seçin',
  TEAM_SAVE: 'Kaydetmek',
  TEAM_DELETE: 'Silmek',
  TEAM_CREATE: 'Oluşturmak',
  TEAM_CLONE: 'Kopya',
  TEAM_NAME: 'İsim',
  TEAM_CODE: 'Kodlamak',
  TEAM_ACTION_DELETE_CONFIRM: 'Gerçekten silmek mi istiyorsun',
  TEAM_ACTION_DELETE_CONFIRM_TITLE: 'Dikkat olmak!',
  TEAM_ACTION_DELETE_CONFIRM_EXTENDED: 'Silindikten sonra geri yüklenemez!',
  TEAM_FULL_ANSWER_ON_LABEL: 'Etikette tam cevap',
  TEAM_RESTRICTED_TEAM: 'Kısıtlı',
  TEAM_SEGREGATED_DATA: 'Ayrılmış veriler',
  TEAM_PRIVATE_TEAM: 'Özel takım',
  TEAM_AUTO_ASSIGN: 'Otomatik atama',
  TEAM_MAILBOX_IN: 'Gelen posta kutusu',
  TEAM_MAILBOX_OUT: 'Giden posta kutusu',
  TEAM_DEFAULT_LANGUAGE: 'Varsayılan dil',
  TEAM_COUNTRIES: 'Atanan Ülkeler',
  TEAM_PRODUCT_GROUPS: 'Ürün Grupları atayın',
  TEAM_PARENT: 'Ana takım',
  TEAM_OVERRIDE_CLUSTER_ROOT: 'Küme kök ekibini geçersiz kılmak',
  TEAM_AFFILIATIONS: 'Bağlı Takımlar',
  TEAM_TIMEZONE: 'Saat dilimi',
  UPDATE: 'Güncelleme',
  AUTO_ASSIGN_BY: 'Otomatik atama',
  AUTO_ASSIGNMENTS: 'Otomatik Atamalar',
  NO_ONE: 'Kimse',
  NOT_APPLICABLE: 'Uygulanamaz',
  OVERRIDE_NOTIFICATIONS: 'Bildirimleri geçersiz kılın',
  OVERRIDE_NOTIFICATIONS_HINT:
    'Geçersiz kılma bildirimleri ile kullanıcı şablonu kullanıcıya ayarlandığında, önceden var olan herhangi bir bildirim ayarlarını devre dışı bırakacak ve yalnızca kullanıcı şablonunda listelenen bildirim ayarlarını etkinleştirecektir',
  CREATED_MANUALLY: 'Manuel olarak oluşturuldu',
  DOCUMENT_TITLE_FOR_WORD:
    'Kelime belgelerinin başlığı genellikle belgenin kendisi içindeki biçimlendirilmiş bölümden alınır. Belge başlık içermiyorsa, burada belirtebilirsiniz. Burada başlık belirtmezseniz ve Word belgesi başlık biçimlendirilmiş bölüm içermezse, belge kimliği başlık için kullanılır.',
  ORDER_BY: 'Tarafından sipariş',
  FORMAT: 'Biçim',
  SELECT_QUESTION: 'Soru Seçin',
  SELECT_QUESTION_FOR_NOTE: 'Not eklemek istediğiniz soruyu seçin',
  ADD_NOTE_FOR_WHOLE_ENQUIRY: 'Tüm araştırma için not ekleyin',
  REQUESTED_DATE: 'Talep edilen tarih',
  UNASSIGNED: 'Atanmamış',
  BLANK: 'Boşluk',
  ANSWER_SAVED_WITHOUT_EMAIL: 'E -posta olmadan kaydedilen cevap',
  INQ_RELATED_TO: 'İle ilgili sorular',
  QUESTION_TEXT_BEFORE_SPLIT: 'Bölünmeden önce soru metni',
  SPLIT_MERGE: 'Bölmek ve birlestirmek',
  CLARIFICATION_QUESTION: 'Açıklama sorusu',
  ACTIVE_ON: 'Aktif',
  DISMISS: 'Azletmek',
  GO_TO_ENQUIRY: 'Soruşturmaya git',
  NO_TEAMS: 'Takımsız',
  NO_TEAMS_TIP:
    'Herhangi bir takıma atanmayan kullanıcıları içerir. Bunlardan bazılarının ekibinizden olup olmadığını kontrol edebilir ve onlara uygun rolü verebilirsiniz.',
  TASKS: 'Görevler',
  Therapeutic_Areas: 'Terapötik alanlar',
  Product_Groups: 'Ürün grupları',
  Licensing_Partner: 'Lisans ortağı',
  Product_Code: 'Ürün Kodu',
  SELECT_EXISTING_ONE: 'Mevcut olanı seçin',
  OR: 'veya',
  MANAGE_FORM: 'Formu Yönetin',
  EVENT_FORM_ADDRESS: 'Olay Formu Adresi',
  EVENT_FORM_PREVIEW_WARING:
    'Kongre formunu açmadan veya adresini kopyalamadan önce herhangi bir değişiklik kaydettiğinizden emin olun',
  SEARCH_SAVED_SEARCHES: 'Kayıtlı aramaları arayın',
  GO: 'Gitmek',
  ASSIGN: 'Atamak',
  CERTIFICATION: 'Sertifika',
  SORT: 'Çeşit',
  ADD_MAILBOX: 'Posta Kutusu Ekle',
  UNSET_INCOMING_MAILBOX: 'Gelen posta kutusunu açın',
  SET_INCOMING_MAILBOX: 'Gelen posta kutusunu ayarlayın',
  UNSET_OUTGOING_MAILBOX: 'Giden posta kutusunu açın',
  SET_OUTGOING_MAILBOX: 'Giden posta kutusunu ayarlayın',
  ALL_TEAMS_WITH_CURRENT_MAILBOX: 'Mevcut posta kutusu olan tüm takımlar',
  ALL_TEAMS_WITH_MAILBOX: 'Posta Kutusu ile Tüm Takımlar',
  MAILBOX: 'Posta kutusu',
  EMAIL_POLLING: 'E -posta Yoklama',
  ADVANCED_SEARCH: 'gelişmiş Arama',
  SELECT_FIELDS: 'Alanları seçin',
  SEARCH_TERM: 'Arama terimi',
  COLUMN_INTERACTION_ID: 'Etkileşim kimliği',
  COLUMN_QUESTION_ID: 'Soru kimliği',
  COLUMN_CREATED_ON: 'Onda',
  COLUMN_LAST_EDITED_DATE: 'Son düzenlendi',
  COLUMN_ASSIGNED_TO: 'Atamak',
  COLUMN_DUE_DATE: 'Bitiş tarihi',
  COLUMN_FOLLOW_SENT_DATE: 'Takip Gönderilen Tarih',
  COLUMN_FOLLOW_RECEIVED_DATE: 'Takip Alınan Tarih',
  COLUMN_ANSWER_DATE: 'Cevap Tarihi',
  COLUMN_CLOSED_ON_DATE: 'Tarihte kapalı',
  COLUMN_REOPENED_DATE: 'Yeniden açılan tarih',
  COLUMN_INSERTED_BY: 'Tarafından eklendi',
  COLUMN_LAST_EDITED_BY: 'En son düzenlendi',
  COLUMN_ANSWERED_BY: 'Tarafından cevaplandı',
  COLUMN_APPROVED_BY: 'Tarafından onaylandı',
  COLUMN_INT_COMM_USER: 'Dahili iletişim kullanıcısı',
  COLUMN_INT_COMM_DATE: 'İç İletişim Tarihi',
  COLUMN_MY_TEAM: 'Benim takımım',
  COLUMN_PQC_RELATED: 'PQC ile ilgili',
  COLUMN_INCOMING_CHANNEL: 'Gelen kanal',
  COLUMN_OUTGOING_CHANNEL: 'Giden kanal',
  COLUMN_OFF_LABEL: 'Etiket kapalı',
  COLUMN_HCP_SPEC: 'HCP + uzmanlığı',
  COLUMN_NON_HCP_SPEC: 'HCP dışı meslek',
  COLUMN_KEE: 'Kee',
  COLUMN_HOSPITAL_BASED: 'Hastane merkezli',
  COLUMN_CITY: 'Şehir',
  COLUMN_INSTITUTION: 'Kurum',
  COLUMN_TERRITORY: 'Bölge',
  CASE_ID: 'dava numarası',
  OPTION_ADD: 'EKLE',
  OPTION_AND: 'VE',
  OPTION_OR: 'VEYA',
  OPTION_NOT: 'OLUMSUZLUK',
  OPTION_YES: 'Evet',
  OPTION_NO: 'Numara',
  QUERY: 'Sorgu',
  EXPORT_LABEL: 'Dışa aktarma sütunlarını tanımla',
  SAVE_SEARCH: 'Kaydet arama',
  SHARE_SEARCH: 'Paylaşım',
  SEARCH_BTN: 'Arama',
  BACK_ACTION: 'Geri',
  SAVE_CHANGES: 'Değişiklikleri Kaydet',
  SAVED_SEARCH: 'Kaydedilmiş Arama',
  NEW: 'Yeni',
  SAVE_AS: 'Ad olarak Kaydet',
  ENTER_RECIPIENTS: 'Alıcıları girin',
  CLEAR_SAVED_SEARCH: 'Kurtarılan aramaları temizleyin',
  NO_SAVED_SEARCH: 'Kaydedilmiş arama bulunamadı',
  INT_COMM_TEXT: 'Dahili iletişim metni',
  FOLLOW_UP_TEXT: 'Takip metni',
  CONFIRM_DELETION: 'Silmeyi onayla',
  ARE_SURE: 'Kaydedilen aramayı silmek istediğinden emin misin',
  PERMANENTLY_DELETED: 'Arama kalıcı olarak <b> silinecektir </b>.',
  NOTHING_SAVE: 'Tasarruf edecek bir şey yok.',
  NOTHING_SEARCH: 'Arayacak hiçbir şey yok.',
  NOTHING_EXPORT: 'Aramanın dışa aktarma kaydı yoktur.',
  SHARE_SUCCESS: 'Arama başarıyla paylaşıldı',
  SHARE_FAILURE: 'Arama paylaşılamadı',
  DELETED_SUCCESS: 'Başarılı bir şekilde silinmiş arama',
  ALL_DELETED_SUCCESS: 'Kaydedilen tüm aramaları başarıyla sildi',
  VIEW_SAVED_SEARCH: 'Kaydedilen aramayı görüntüle',
  EDIT_SAVED_SEARCH: 'Kaydedilen aramayı düzenle',
  EXPORT_SAVED_SEARCH: 'Kayıtlı Arama Dışa Aktar',
  EXPORT_SEARCH: 'İhracat Arama',
  DELETE_SAVED_SEARCH: 'Kaydedilen aramayı sil',
  SELECT_EXPORT_COLUMNS: 'Dışa aktarmak için sütunları seçin',
  SAVED_SEARCH_ALREADY_EXIST: 'Zaten bu isimle arama.',
  CHARACTER_LIMIT: 'Min 3 karakter. Maksimum 35 karakter.',
  CHARACTER_LEFT: 'Karakterler kaldı',
  ACTIVATE: 'Etkinleştirmek',
  INACTIVE_USER: 'Bu kullanıcı artık etkin değil. Lütfen sistem yöneticinizle iletişime geçin.',
  FOLLOW_UP: 'Takip et',
  USER_NOT_FOUND: 'Kullanıcı bulunamadı',
  DEPARTMENTS: 'Departmanlar',
  EMAIL: 'E -posta',
  ADD_NEW_DEPARTMENT: 'Yeni Departman Ekle',
  DEPARTMENT_COUNTRY: 'ÜLKE',
  DEPARTMENT_COMPANY: 'ŞİRKET',
  DEPARTMENT_TEAM: 'TAKIM',
  DEPARTMENT_TA: 'Terapötik alan',
  DEPARTMENT_PRODUCT: 'ÜRÜN',
  DEPARTMENT_ACTIONS: 'HAREKETLER',
  DEPARTMENT_CONFIRMATION_1: 'Silmeyi onayla?',
  DEPARTMENT_CONFIRMATION_2: 'Bu eylem geri döndürülemez.',
  DEPARTMENT_NAME: 'Bölüm Adı',
  TYPE_DEPARTMENT: 'Tip',
  COUNTRY_PLEASE_SELECT_TEAM_FIRST: 'Ülke - lütfen önce takımı seçin',
  DEP_NAME_CHARACTER_LIMIT: 'Maksimum 200 karakter.',
  AUTH_REQUESTS: {
    TITLE: {
      UserRequest: 'Kullanıcı isteği',
      CategoryTopicRequest: 'Kategori/Konu İsteği',
      ProductRequest: 'Ürün talebi',
      UserUpdateRequest: 'Kullanıcı Güncelleme İsteği',
    },
    TYPE: {
      NewUser: 'Yeni kullanıcı',
      NewCategoryTopic: 'Yeni kategori/konu',
      NewUserUpdate: 'Kullanıcı Güncellemesi',
    },
  },
  ADMINISTRATION_SECTION: {
    SPECIALIZATION: {
      DEFINE_COUNTRY_SPECIFIC_SPECIALIZATIONS: 'Ülkeye özgü uzmanlık listesini tanımlayın',
      IS_COUNTRY_SPECIFIC: 'Ülkeye özgü mü',
      TYPE: 'Sorgulayıcı Türü',
      SPECIALIZATION_EXISTS: '"Sorgulayıcı Türü/Uzmanlığı" için yapılandırma zaten mevcut.',
    },
    AUTHORIZATION_REQUESTS: { GENDER: 'Cinsiyet', COUNTRY: 'Ülke', TITLE: 'Başlık' },
  },
  hcp: 'HCP',
  emp: 'Çalışan',
  cons: 'HCP olmayan',
  OOO_DEPUTY: 'OOO Milletvekili',
  NO_DEPUTY: 'Milletvekili yok',
  UNSPECIFIED_END_DATE: 'Belirtilmemiş bitiş tarihi',
  DELEGATORS: 'Delegatörler',
  NO_DELEGATORS: 'Delegatör yok',
  PREVIEW_DELEGATORS: 'Önizleme delegörleri',
  REMOVE_DEPUTY: 'Yardımcısı Kaldır',
  USERS_WHO_SET_YOU_AS_DEPUTY: 'Sizi milletvekili olarak ayarlayan kullanıcılar',
  CHANGED_USER: 'Kullanıcı değiştirildi',
  DEFAULT: 'Varsayılan',
  SPECIALIZATIONS: 'Uzmanlık',
  ENQUIRERS_LIST: 'Enquirers listesi',
  EDIT_THE_RECORD: 'Kayıt Düzenle',
  DEACTIVATE_THE_RECORD: 'Kaydı devre dışı bırak',
  USER_PROFILE: {
    OUT_OF_OFFICE: {
      OUT_OF_OFFICE: 'Ofis dışında',
      HINT: 'Yalnızca Ofis dışı olmayan sorgulama rolü olan aktif kullanıcılar seçilebilir.',
      MODAL_TITLE: 'Ofis Dışı Ayarları onaylayın',
      MODAL_MSG_1:
        'Hemen yürürlüğe girerek ofis seçeneğinden çıktınız, tüm sorularınız derhal milletvekiline atanacaktır.',
      MODAL_MSG_2:
        'Ofis seçeneğinden çıktınız, tüm sorularınız ofis dışı dönemde yardımcısına atanacaktır.',
    },
  },
  GENDER_MALE: 'Bay.',
  GENDER_FEMALE: 'Bayan.',
  TITLE_DR: 'Dr.',
  TITLE_PHD: 'Doktora',
  TITLE_PROF: 'Prof.',
  TITLE_PROF_DR: 'Profesör Doktor.',
  HOME: 'Ev',
  HOSPITAL: 'Hastane',
  OFFICE: 'Ofis',
  WORK: 'İş',
  INSERT_IMAGE: 'Resim ekle',
  USER_ADMIN: 'Kullanıcı yöneticisi',
  MAILBOXES: 'Posta kutuları',
  AUTHORIZATION_REQUESTS: 'Yetkilendirme Talepleri',
  METADATA: 'Meta veriler',
  NOTIFICATIONS_PAGE: {
    TITLES: {
      ASSIGNMENT: 'Atama',
      FORWARD: 'İleri',
      FOLLOW_UP: 'Takip et',
      DUE_DATE_SETTINGS: 'Son Tarih Ayarları',
      CLOSE: 'Kapat',
      DELIVERY_ERROR: 'Teslimat hatası',
      SCHEDULER: 'Zamanlayıcı',
      USER_REPORTS: 'Kullanıcı Raporları',
      DOCUMENT_APPROVAL: 'Belge onayı',
      AUTHORIZATION_REQUEST: 'Yetkilendirme Talepleri',
      NEW_INQUIRY: 'Yeni soruşturma',
      INQUIRY_APPROVAL: 'Sorgulama Onayı',
    },
    TYPES: {
      AUTHORIZATION_REQUEST_1: 'Bir rol talebi onaylandı/reddedildi',
      AUTHORIZATION_REQUEST_3: 'Bir rol benim onayımı bekliyor',
      DOCUMENT_APPROVAL_1: 'Bir belge onayınızı bekliyor',
      DOCUMENT_APPROVAL_2: 'Bir belge onaylandı/reddedildi',
      DOCUMENT_APPROVAL_3: 'Bir belge arşivlendi',
      DOCUMENT_APPROVAL_4: 'Değişikliklerle bir belge onaylandı',
      DOCUMENT_APPROVAL_5: 'Bir belge arşivlenecek',
      DOCUMENT_APPROVAL_6: 'Onaylanmış belgelerin listesi',
      DUE_DATE_2: 'Geçmişten sonra tekrarlayın',
      ERROR_1: 'Teslimat Bildirimi hatası (örn. Yanlış e -posta)',
      FOLLOW_UP_1: 'Müşteriye bir takip veya hatırlatma gönderildi',
      FOLLOW_UP_4: 'Bir takip alındı',
      IMPORT_DATA_1: 'İthalat sırasında yeni bir nesne oluşturuldu',
      IMPORT_DATA_4: 'İthalat sırasında yeni bir kullanıcı oluşturuldu',
      IMPORT_DATA_5:
        'Bir kullanıcı zaten var olan bir kullanıcı ile eşleşiyordu, ancak önceki bir içe aktarmadan gelmiyordu',
      IMPORT_DATA_6: 'Harici bir kaynaktan verilerin içe aktarılması sırasında bir hata oluştu',
      METADATA_MANAGEMENT_1: 'Yeni bir meta veri yaratıldı',
      NEW_DOCUMENT_1: 'Ülkemde yeni onaylanmış belge mevcut',
      NEW_DOCUMENT_2: 'Yerel onay için yeni belge mevcuttur',
      NEW_DOCUMENT_3: 'Avrupa / küresel onay için yeni yerel belge mevcuttur',
      NEW_DOCUMENT_4: 'Yeni Global Belge mevcut',
      NEW_DOCUMENT_5: 'Yeni Avrupa belgesi mevcut',
      NEW_DOCUMENT_6: 'Küresel onay için yeni Avrupa belgesi mevcuttur',
      SCHEDULER_TASK_1: 'Bir görevin yürütülmesi sırasında bir hata oldu',
      USER_REPORTS_1: 'Aktif Kullanıcıların Listesi',
      TEXT_GUIDES: {
        AUTHORIZATION_REQUEST_3:
          'Bir kullanıcı rolü isteğinin onaylanması/reddedilmesi gerektiğinde sizi bilgilendiren bildirimler alın',
        AUTHORIZATION_REQUEST_2:
          'Yeni kullanıcı oluşturulduğunda veya ekibinizdeki bir kullanıcı tarafından bir rol istendiğinde bildirim alın',
        AUTHORIZATION_REQUEST_4: 'Yeni bir konu ve/veya kategori önerildiğinde bildirim alın',
        FOLLOW_UP_4:
          'Bana atanan ve sorgulayan kişiden Takip yanıtı alan sorgular için bildirimler al',
        NEW_INQUIRY_1:
          'Ülkenizde alınan, bir sorgulayan/kullanıcı tarafından eklenen veya başka bir ekipten iletilen yeni sorgular için bildirimler alın',
        ANSWER_2:
          'Bir işbirlikçi olarak çalıştığımı ancak doğrudan sorguya atanmadığımı belirten yanıtlanan sorgular için bildirimler alın',
        DUE_DATE_1:
          'Bu seçeneği seçtiğinizde aşağıdaki bildirimlerin hepsi etkinleştirilecektir. Bildirim sayısını azaltmak için ihtiyaç duyulmayanların seçimini kaldırın.',
        INQUIRY_APPROVAL_5:
          "Önerilen cevabınız Reddedildi veya Etkileşim durumu, etkileşim yoluyla bölünme, yeni sorgu olarak birleştirme sonucu bölünme veya daha sonraki sorgu onayı ihtiyacı açısından ekip ayarında herhangi bir değişiklik nedeniyle Onay için Gönderildi'den Devam Ediyor'a güncellendi.",
        INQUIRY_APPROVAL_4: 'Önerdiğiniz cevap Onaylandı.',
      },
      FREQUENCIES: {
        INSTANTLY: 'Anında',
        DAILY: 'Günlük',
        WEEKLY: 'Haftalık',
        QUARTERLY: 'Üç ayda bir',
        MONTHLY: 'Aylık',
        SECOND_DAY: 'Her gün her gün',
        THIRD_DAY: 'Her üç gün',
        ONE_WEEK: 'Bir hafta',
        ONE_MONTH: 'Bir ay',
        TWO_MONTHS: 'İki ay',
        THREE_MONTHS: 'Üç ay',
        SIX_MONTHS: 'Altı ay',
        NINE_MONTHS: 'Dokuz ay',
        EVERY_TWO_WEEKS: 'İki haftada bir',
        EVERY_THREE_WEEKS: 'Her üç haftada bir',
        EVERY_FOUR_WEEKS: 'Her dört haftada bir',
      },
      OPTIONS: {
        ALMOST_DUE: 'Neredeyse',
        DUE_NOW: 'Şimdi',
        DUE_SOON: 'Yakında',
        EARLY_ON: 'Erken',
        TEXT_GUIDES: {
          DUE_NOW: 'Soruşturma zamanı geldiğinde bildirim alın',
          EARLY_ON:
            'Son teslim tarihi yaklaşan sorular için erken bildirimler alın: Normal 4 gün - Yüksek 2 gün - Kritik Son teslim tarihinden 24 saat önce',
          DUE_SOON:
            'Son teslim tarihi yaklaşan sorgular için bildirim alın: Normal 48 saat - Yüksek 12 saat - Kritik Son teslim tarihinden 6 saat önce',
          ALMOST_DUE:
            'Son teslim tarihine yakın olan sorgular için bildirim alın: Normal 24 saat - Yüksek 6 saat - Kritik son teslim tarihinden 3 saat önce',
        },
      },
      AUTHORIZATION_REQUEST_4: 'Ekibimde bir kategori/ konu talep edildi',
      AUTHORIZATION_REQUEST_2: 'Kullanıcı istekleri',
      IMPORT_DATA_2: 'İthalat sırasında yeni bir sorgulayıcı oluşturuldu',
      IMPORT_DATA_3:
        'Bir sorgulayıcı, halihazırda var olan bir sorgulayıcıyla eşleşiyordu ancak önceki bir ithalattan gelmiyordu',
      ANSWER_2: 'Dahil olduğum bir soruşturmaya cevap verildi',
      ASSIGNMENT_1: 'Bana bir Soruşturma atandı',
      ASSIGNMENT_5: 'Vekilimize bir soruşturma atandı',
      CLOSE_1: 'Bir Soruşturma Kapatıldı',
      DUE_DATE_1: 'Bana verilen bir soruşturmanın son teslim tarihi yaklaşıyor, bana bildirin',
      FOLLOW_UP_7: 'Bir Soruşturma Takip Edilmeden Kayboldu',
      FORWARD_1: 'Bir Soruşturma İletildi',
      INQUIRY_APPROVAL_1: 'Bir Soruşturma onayınızı bekliyor',
      INTERNAL_1: 'Atanan sorguma bir not eklendi',
      NEW_INQUIRY_1: 'Ülkemde yeni bir soruşturma alındı',
      SPAM_1: "Spam'da yeni bir sorgu alındı",
      ANSWER_1: 'Benim tarafımdan yanıtlanan etkileşimlerdeki yanıtın kopyasını al',
      ANSWER_6: 'Benim tarafımdan onaylanan etkileşimlerde cevabın kopyasını al',
      INQUIRY_APPROVAL_5: 'Önerdiğiniz Cevap Reddedildi',
      INQUIRY_APPROVAL_4: 'Önerdiğiniz Cevap Onaylandı',
    },
  },
  NEW_TEAM: 'Yeni takım',
  SmPc: 'SMPC',
  USERS: 'Kullanıcılar',
  PAGINATOR_NEXT_PAGE_LABEL: 'Sonraki Sayfa',
  PAGINATOR_PREV_PAGE_LABEL: 'Önceki sayfa',
  PAGINATOR_FIRST_PAGE_LABEL: 'İlk sayfa',
  PAGINATOR_LAST_PAGE_LABEL: 'Son Sayfa',
  PAGINATOR_ITEMS_PER_PAGE_LABEL: 'Sayfa başına öğeler',
  Date: 'Tarih',
  Action: 'Eylem',
  'Action by user': 'Kullanıcı tarafından eylem',
  'Changed user': 'Kullanıcı değiştirildi',
  'Original value': 'Orijinal değeri',
  'New value': 'Yeni değer',
  REGION: 'Bölge',
  TEAM_REGION_LABEL: 'Bölge - Lütfen önce şirket seçin',
  TEAM_THERAPEUTIC_AREA_LABEL: 'Terapötik alanlar - Lütfen önce şirketi seçin',
  TEAM_PRODUCT_LABEL: 'Ürünler - Lütfen önce terapötik alanı seçin',
  TEAM_COUNTRIES_LABEL: 'Ülkeler - Lütfen Önce Bölgeyi Seçin',
  DEFINE_PRODUCTS_FOR_EACH_COUNTRY: 'Her ülke için ürünleri tanımlayın',
  PLEASE_SELECT_AT_LEAST_ONE_PRODUCT_FOR: 'Lütfen en az bir ürün seçin',
  VIEW_CHANGES: 'Değişiklikleri Görüntüle',
  Sales_Representative: 'Satış Temsilcis',
  Other_Commercial: 'Diğer Ticari',
  REGIONS: 'Bölgeler',
  MAIN_CLIENT: 'Ana müşteri',
  LICENSE_PARTNER: 'Lisans ortağı',
  TEAM_SET_UP: 'Takım kuruldu',
  LIST_TEAMS: 'Takımları listeleyin',
  COMPANIES: 'Şirket',
  DOMAINS: 'Alanlar',
  TOKEN_REFRESH_NOTICE: 'Enqmed hesap kaydınız için yeni bir onay e -postası istediniz.',
  TOKEN_REFRESH_SUCCESS: 'E -postanıza yeni bir onay bağlantısı gönderildi.',
  TOKEN_REFRESH_ERROR:
    'Bir hata oluştu. Daha fazla yardıma ihtiyacınız varsa lütfen destekle iletişime geçin.',
  PLEASE_ENTER_MAX: 'Lütfen maksimum girin',
  COMPANY_ACTION_EDIT_CONFIRM_TITLE:
    'Lütfen varsa, aşağıdaki değişiklikleri inceleyin ve şirketin değiştirilmesi gerekip gerekmediğini onaylayın.',
  COMPANY_ACTION_EDIT_WARNING_MESSAGE:
    'Değişiklikler ekipleri etkileyeceğinden lütfen dikkatle devam edin',
  THERE_ARE_NO_CHANGES: 'Değişiklik yok.',
  COMPANY_DIALOG_VALIDATION_MESSAGE_FOR_DUPLICATE:
    'Lütfen aynı değerlere sahip şirketimiz olduğu için iletişim kutusunun arkasındaki masadaki mevcut şirketleri kontrol edin.',
  SIMPLE_TEXT:
    'Lütfen yalnızca harfleri (A-Z, A-Z), sayılar (0-9) ve (Dash, Dönem, Uzay ve Kolon) gibi özel sembolleri girin.',
  COMPANY_DIALOG_VALIDATION_MESSAGE_FOR_REGIONS: 'Lütfen bölge için en az bir ülke seçin',
  COMPANY_DIALOG_VALIDATION_MESSAGE_FOR_THERAPEUTIC_AREAS:
    'Lütfen terapötik alan için en az bir ürün seçin',
  COMPANY_DIALOG_VALIDATION_MESSAGE_FOR_PRODUCTS:
    'Birden fazla terapötik alana ait ürün/seçtiniz, lütfen gerekli tüm terapötik alanları seçin.',
  HTML_CHANGES: 'HTML Değişiklikler',
  SIDE_BY_SIDE_VIEW: 'Yan yana',
  DOMAIN_DIALOG_VALIDATION_MESSAGE_FOR_DUPLICATE:
    'Aynı değerlere sahip zaten etki alanımız olduğu için lütfen iletişim kutusunun arkasındaki tablodaki mevcut etki alanlarını kontrol edin.',
  OUT_CHANNEL_ERROR_MSG: "Giden Kanal Mektubu Enquirer'ın posta adresi",
  OUT_CHANNEL_ERROR_MSG_LONG:
    "Giden kanal mektup olarak seçildi. Lütfen Enquirer Alanına Enquirer'ın posta adresini girin",
  LABEL_FOR: 'İçin etiketlemek',
  male: 'Erkek',
  female: 'Dişi',
  ARCHIVE_ROLE_DIALOG_WARNING_MESSAGE:
    'Bu rolü arşivleme haklarınız yok. Takım için sadece <b> Mims </b> ekip üyeleri için rol arşivleyebilir.',
  SEND_EMAIL_UPON_APPROVAL_OR_REJECTION: 'İstek onayı/reddi üzerine kullanıcıya e -posta gönderin',
  APPROVE_REQUEST_QUESTION: 'İsteği onaylamak ister misiniz?',
  NEW_USER_REQUEST: 'Yeni Kullanıcı İsteği',
  OPEN_REQUESTS: 'Açık istekler',
  APPROVED_REQUESTS: 'Onaylanmış istekler',
  REJECTED_REQUESTS: 'Reddedilen istekler',
  USER_REQUESTS: 'Kullanıcı istekleri',
  REQUEST_FOR: 'Talep etmek',
  CATEGORY_TOPIC_REQUESTS: 'Kategori/Konu İstekleri',
  WITH_THE_FOLLOWING_PAIRS: 'Aşağıdaki çiftlerle:',
  MY_TEAM_OPEN_REQUESTS: 'Açık istekler',
  MY_TEAM_APPROVED_REQUESTS: 'Onaylanmış istekler',
  MY_TEAM_REJECTED_REQUESTS: 'Reddedilen istekler',
  DOCUMENT_APPROVAL_WITHIN_ENQMED: 'EnqMed içinde belge onayı',
  DOCUMENTS_APPROVAL: 'Belgeler Onayı',
  DOCUMENTS_APPROVAL_TITLE: 'Seçilen ekip için belge onay süreci için ayarlar',
  OUT_CHANNEL_FAX_ERROR_MSG_LONG:
    'Faks olarak seçilen giden kanal. Lütfen Enquirer bölgesindeki Enquirer’ın Faks Numarasını girin',
  OUT_CHANNEL_TELEPHONE_ERROR_MSG_LONG:
    'Giden kanal telefon olarak seçildi. Lütfen Enquirer’ın Enquirer bölgesindeki telefon numarasını girin',
  OUT_CHANNEL_FAX_ERROR_MSG: 'Giden Kanal Faks Enquirer’ın Değeri',
  OUT_CHANNEL_TELEPHONE_ERROR_MSG: 'Giden Kanal Telefon Enquirer’ın Değeri',
  COPYRIGHT_PART_ONE: 'Telif hakkı',
  COPYRIGHT_PART_TWO: 'Madjenta Ltd. Tüm hakları saklıdır.',
  REVIEWED_DATE: 'İncelenen tarih',
  OUT_CHANNEL_EMAIL_ERROR_MSG_LONG:
    'Giden kanal e -posta olarak seçildi. Lütfen Enquirer’ın E -E -E -postası Enquirer Alanında girin',
  OUT_CHANNEL_EMAIL_ERROR_MSG: 'Giden Kanal E -posta Enquirer’ın Değeri',
  ENTER_USER_COMMENT_OPTIONAL: 'Kullanıcıya yorum girin (isteğe bağlı)',
  SELECT_ANOTHER_CATEGORY: 'Başka bir kategori seçin',
  SELECT_ANOTHER_PRODUCT: 'Başka bir ürün seçin',
  SUGGESTED_TOPIC: 'Önerilen konu',
  EDIT_USER_REQUEST: 'Kullanıcı İsteğini Düzenle',
  NEW_TOPIC_REQUEST: 'Yeni konu önerildi',
  EDIT_TOPIC_REQUEST: 'Önerilen konuyu düzenle',
  NEW_CATEGORY_REQUEST: 'Yeni kategori önerildi',
  EDIT_CATEGORY_REQUEST: 'Önerilen kategoriyi düzenle',
  REVIEW_COMMENT: 'Gözden Geçirme Yorumu',
  DOCUMENTS_APPROVAL_WORKFLOW: {
    PAGE_TITLE: 'Belge Onay İş Akışları',
    CREATE_NEW: 'Yeni iş akışı oluştur',
    CUSTOMIZE: 'İş Akışını Özelleştir',
    NAME: 'İş akışı adı',
    ERROR_MSG_DUPLICATE_NAME: 'Bu adla iş akışı zaten var.',
    SAVE: 'İş Akışını Kaydet',
    DELETE: 'İş Akışını Sil',
    STEP: 'Adım',
    ALL_USERS_WITH_DOC_AUTHOR_ROLE: 'Belge yazarı rolü olan tüm kullanıcılar.',
    APPROVAL_DEADLINE: 'Onay tarihi',
    SELECT_APPROVAL_TYPE: 'Onay Türünü Seçin',
    SELECT_APPROVAL_TYPE_FIRST: 'Önce onay türünü seçin',
    SELECT_REVIEWERS: 'Gözden geçirenleri seçin',
    ACTION_ON_APPROVE_WITH_CHANGES: 'Değişikliklerle onaylama eylemi',
    ACTION_ON_REJECT: 'Reddedeki Eylem',
    FILL_ALL_REQUIRED_FIELDS: 'Yeni adım eklemek için lütfen gerekli tüm alanları doldurun.',
    ALL_APPROVAL_TYPES_USED:
      'Mevcut adım için mevcut tüm türleri kullandığınız için daha fazla onay alt adım ekleyemezsiniz.',
    CONFIRMATION_ROW_1: 'Takım için belge onay iş akışını silmeye çalışıyorsunuz {{ NAME }}.',
    CONFIRMATION_ROW_2: 'Gerçekten onay iş akışını silmek istiyor musunuz?',
    CONFIRMATION_TITLE: 'Belge Onay İş Akışı Silinimi',
    TEAM_VIEW_SELECT_EXISTING: 'Aşağıdaki listeden mevcut bir belge onayı iş akışı seçin veya ',
    DOC_VIEW_SELECT_EXISTING: 'Mevcut bir belge onayı iş akışı seçin',
    ONLY_SINGLE_APPROVAL_STEP_CAN_BE_LISTED_AS_FINAL:
      'Yalnızca tek onay adımı nihai olarak listelenebilir.',
    APPROVAL_TYPE_TOOLTIP:
      'Bu ekip için belge onayı rolü olan kullanıcı yoktur. Lütfen önce onay rolleri ekleyin.',
    INFO_DIALOG_TITLE: 'Bu ekip için belge onayı iş akışı ayarlanmamıştır.',
    INFO_DIALOG_MSG:
      "Ekip için belge onayı iş akışı oluşturmak için lütfen yerel MIM'inizle iletişime geçin.",
    STEP_LIMIT_REACHED: '{{ MAX_STEPS }} onay adımının sınırına ulaştınız.',
  },
  DOCUMENTS_WITHOUT_APPROVAL: 'Onay gerektirmeyen belge türleri',
  PLEASE_SELECT_TYPE: 'Lütfen Türü Seçin',
  ABORT_FILE_UPLOAD: 'Dosya Yükle',
  USERS_REPORT: 'Kullanıcılar raporu',
  DOCUMENT_CREATE_CONFIRM_CLOSE_MSG:
    'Bu pencereyi kapatarak zaten doldurulmuş bilgileri kaybedeceksiniz.',
  DO_YOU_WANT_TO_PROCEED: 'Devam etmek istiyor musunuz?',
  USERS_REPORT_DESCRIPTION:
    "Daiichi Sankyo Avrupa onkolojisi ve kardiyovasküler için MIM'in MIM rolüne sahip olan tüm ek ekiplerin kullanıcılarının listesi.",
  TEMPLATE_CONFIRM_MSG: 'Şablon silindikten sonra geri yüklenemez!',
  EVENT_CONFIRM_MSG: 'Etkinlik arşivlendikten sonra geri yüklenemez!',
  CREATE_DRAFT: 'Taslak Oluştur',
  CREATE_USER: 'Kullanıcı oluştur',
  ADD_ROLE: 'Rol eklemek',
  DOMAIN_ADDRESS_INVALID_FOR: 'Etki Adı Adresi Geçersiz',
  AVAILABLE_ADDRESSES: 'Mevcut adresler',
  IN_TEAM: 'Ekipte',
  TO_THE_SELECTED_USER: 'seçilen kullanıcıya',
  USER_INFORMATION: 'Kullanıcı bilgisi',
  LOGIN_VERIFY_EMAIL_ADDRESS:
    'Etki alanı adresi ekibinizin/profilinizin ilişkilendirildiği ekibine karşılık gelmediğinden, kullandığınız e -posta adresi güncellenmelidir. <br> Lütfen yardım için sistem yöneticinize danışın veya destek ekibine başvurun.',
  MESSAGE: 'İleti',
  IN_APPROVAL: 'Onayla',
  IN_REVIEW: 'İncelemede',
  DOCUMENT_APPROVAL: 'Belge onayı',
  DOCUMENT_TITLE: 'Belge başlığı',
  SEARCH_DOCUMENTS: 'Arama belgeleri',
  APPROVAL_INBOX: 'Onay gelen kutusu',
  FILTER_DOCUMENTS_BY: 'Filtre Belgeleri',
  submit_to_approval: "Onay'a gönder",
  create_document_metadata: 'Belge Meta Verileri Oluşturun',
  archive: 'Arşiv',
  I_ACKNOWLEDGE_THAT_IT_IS_:
    "Tüm tıbbi ürünlerin tüm olumsuz ilaç reaksiyonlarını (ADR'ler) yerel gereksinimlere göre bildirme yükümlülüğüm olduğunu kabul ediyorum. Enqmed, olumsuz ilaç reaksiyonları hakkında raporlar yakalamayı amaçlamamaktadır - ADR'ler, yerel gereksinimlere göre doğrudan farmakovijilansa bildirilmelidir.",
  VERSION_DIALOG: {
    TITLE: 'Yeni sürüm mevcut',
    MESSAGE:
      'Yazılımın yeni sürümü mevcut!\n Yeni sürümü almanız gerekiyor, bu sürümdeki iyileştirmelerden faydalanmak ve eski sürümü kullanırken sorun yaşamamak için onu edinmeniz gerekiyor.',
    SAVE_BUTTON: 'Yeni Sürümü Alın',
  },
  ACTIVE_INACTIVE: 'Aktif pasif',
  CATEGORY_DIALOG_VALIDATION_MESSAGE_FOR_DUPLICATE:
    'Aynı değerlere sahip bir kategorimiz olduğundan, lütfen iletişim kutusunun arkasındaki tablodaki mevcut kategorileri kontrol edin.',
  ERROR_EMAIL_COULD_NOT_BE_DELIVERED: 'Hata! Başarısız teslimat',
  VIA: 'aracılığıyla',
  INSERTED_MANUALLY: 'Manuel olarak eklendi',
  AS_A: 'olarak',
  PRODUCT_DIALOG_VALIDATION_MESSAGE_FOR_DUPLICATE:
    'Aynı değerlere sahip ürünümüz zaten olduğundan, lütfen iletişim kutusunun arkasındaki tabloda mevcut ürünleri kontrol edin.',
  NOT_PRODUCT_SPECIFIC: 'Ürüne Özel Değil',
  TOPIC_DIALOG_VALIDATION_MESSAGE_FOR_DUPLICATE:
    'Aynı değerlere sahip bir konuya sahip olduğumuz için lütfen iletişim kutusunun arkasındaki tabloda mevcut konuları kontrol edin.',
  PLEASE_ENTER_MAX_CHARACTERS: 'Lütfen maksimum {{ MAX_CHARS }} karakter girin.',
  THANK_YOU_MESSAGE: 'Teşekkür mesajı',
  AS: 'gibi',
  THERAPEUTIC_AREA_PARENT: 'Ebeveyn alanı',
  MAIN_THERAPEUTIC_AREA: 'Ana Tedavi Alanı',
  CHOOSE_AN_IMAGE: 'Bir resim seçin',
  ALLOWED_FORMATS: 'Yalnızca {{ FORMATS }} biçimlerine izin verilir',
  REMOVE_IMAGE: 'Resmi kaldır',
  THERAPEUTIC_AREA_DIALOG_VALIDATION_MESSAGE_FOR_DUPLICATE:
    'Aynı değerlere sahip bir alanımız olduğundan lütfen iletişim kutusunun arkasındaki tabloda mevcut tedavi alanlarını kontrol edin.',
  PLAIN_TEXT_VIEW: 'Düz Metin Görünümü',
  RICH_TEXT_VIEW: 'Zengin Metin Görünümü',
  EDIT_ITEM: "{{ ITEM }}'i düzenleyin",
  CREATE_NEW_ITEM: 'Yeni {{ ITEM }} oluştur',
  ADD_NEW_ITEM: 'Yeni {{ ITEM }} ekle',
  ADD_ITEM: '{{ ITEM }} ekle',
  AND_HENCE_STATUS_CHANGED_FROM: 've dolayısıyla durum değişti',
  FOLLOW_UP_WITH_ENQUIRER: 'Sorgulayıcı ile takip',
  _MUST_BE_INSERTED_TO_SAVE_REVIEW:
    'Değişiklikleri kaydetmek için aşağıdakilerin eklenmesi gerekir:',
  FOLLOW_UP_IS_NOT_POSSIBLE_FOR_CLOSED_INTERACTION:
    'Kapalı bir etkileşim için takip mümkün değildir.',
  STATUS_DROPDOWN_TOOLTIP_TEXT_FOR_IN_REVIEW:
    'İnceleme durumundaki etkileşim. Bu menüyü etkinleştirmek için lütfen incelemeyi kaydedin.',
  QUESTION_TEXT_MIN_SIX_CHARS: 'Soru metni (en az 6 karakter)',
  PLEASE_PROVIDE_AN_ANSWER_: 'Lütfen bir cevap verin (en az 6 karakter veya ek).',
  USER_MUST_RECOVER_PASSWORD_MESSAGE:
    "Kullanıcının 'Şifremi Unuttum' bölümünden şifresini kurtarması gerektiğini lütfen unutmayın.",
  ENABLE_USER_LOGIN_MESSAGE: 'Bu, kullanıcının oturum açmasını sağlayacaktır.',
  DISABLE_USER_LOGIN_AND_NOTIFICATIONS_MESSAGE:
    'Bu, kullanıcı girişini ve bildirimlerini devre dışı bırakacaktır.',
  SET_NOTIFICATIONS_PREFERENCES_MESSAGE:
    'Lütfen bildirim tercihlerinizi kullanıcı profilinizden ayarlayın!',
  SET_PASSWORD: 'Lütfen şifrenizi belirleyin',
  ERASED_PASSWORD_AFTER_ACCOUNT_REACTIVATION:
    'Hesabınızın yeniden etkinleştirilmesinden sonra şifreniz silinmiştir.',
  SPECIALIZATION_DEACTIVATION_CONFIRMATION: 'Uzmanlığın devre dışı bırakılmasını onaylayın!',
  REMOVE_DEACTIVATED_SPECIALIZATION_FROM_ACTIVE_LIST_MESSAGE:
    'Bu, onu aktif uzmanlıkların bulunduğu listeden kaldıracaktır.',
  GO_TO_ROLES: 'Rollere Git',
  CONTACT_INFORMATION_USERNAME_CHANGE_MESSAGE:
    'Kullanıcı iletişim bilgilerinde yaptığınız değişiklikler, giriş e-postasının {{ OLD_USERNAME }} yerine {{ NEW_USERNAME }} olarak değiştirilmesini tetikledi',
  ASSIGN_ROLE_AND_NOTIFICATIONS_MESSAGE: 'Lütfen rolleri ve bildirimleri atayın.',
  DO_YOU_WANT_TO_CONTINUE: 'Devam etmek istiyor musun?',
  COPY_ANSWER_TO_NEW_INTERACTION: 'Yanıtı yeni etkileşime kopyala',
  NEW_INQUIRIES_HAVE_BEEN_IMPORTED_MESSAGE:
    'Yeni sorgular içe aktarıldı. Tüm soruları tanımladığınızdan ve yanıtladığınızdan emin olun.',
  LEAVE_WITHOUT_SAVING: 'Kaydetmeden Çık',
  PENDING_CHANGES_MESSAGE: 'Bekleyen değişiklikler {{ CHANGES }} mı?',
  SPLIT_SELECTED_TEXT_IN_NEW_INTERACTION_QUESTION:
    'Seçilen metni yeni etkileşimde bölmek istediğinizden emin misiniz?',
  SPLIT_SELECTED_TEXT_IN_NEW_INTERACTION_MESSAGE:
    'Bu, seçilen metni etkileşimden kaldıracak ve ondan yeni bir metin oluşturacaktır.',
  DOWNLOAD_ERROR: 'İndirme hatası!',
  DOWNLOAD_INQUIRIES_ERROR_MESSAGE:
    "1000'den fazla sorguyu indirmeye çalışıyorsunuz. Desteklenen maksimum sayı 1000'dir. Lütfen seçiminizi hassaslaştırın.",
  TRYING_TO_CHANGE_QUESTION_CHARACTERIZATION:
    'Soru karakterizasyonunu değiştirmeye çalışıyorsunuz!',
  CHANGING_QUESTION_CHARACTERIZATION_NOT_ALLOW_CHANGES_IN_ANSWER_MESSAGE:
    'Lütfen soru karakterizasyonunu değiştirmenin daha önce verilen cevapta herhangi bir değişikliğe izin vermeyeceğini veya yeni bir cevap gönderilmesine izin vermeyeceğini unutmayın!',
  RE_OPEN_CLOSED_INTERACTION_MESSAGE:
    'Yeni bir yanıt göndermek için kapalı bir etkileşimi yeniden açmak üzeresiniz.',
  CONVERT_FOLLOWUP_TO_QUESTION_MESSAGE: 'Bu Takibi Soruya dönüştürmek istediğinizden emin misiniz?',
  ARE_YOU_SURE: 'Emin misin?',
  USER_DEACTIVATION_CONFIRMATION_QUESTION:
    '{{ USER_INFO }} hesabının devre dışı bırakılmasını onaylayın',
  USER_REACTIVATION_CONFIRMATION_QUESTION:
    '{{ USER_INFO }} hesabının yeniden etkinleştirilmesini onaylayın',
  USER_REACTIVATED_MESSAGE: '{{ USER_INFO }} kullanıcısı başarıyla yeniden etkinleştirildi!',
  DELETE_NOT_POSSIBLE: 'Silme mümkün değil!',
  SEARCH_NO_LONGER_EXIST: 'Bu arama, gönderen tarafından silindiği için artık mevcut değil.',
  LOGIN_CREDENTIALS_CHANGED: 'Giriş Kimlik Bilgileri Değiştirildi!',
  EDIT_TEXT: 'Metni düzenle',
  FOLLOW_UP_ATTEMPTS: 'Takip girişimleri',
  FIRST_FOLLOW_UP_INFO_TEXT: 'Bireysel etkileşim kapsamında gönderilen ilk takip',
  SECOND_AND_THIRD_FOLLOW_UP_INFO_TEXT:
    'İlk talepten sonra aşağıdakilerden herhangi biri devre dışı bırakılırsa süreç bir sonraki adıma geçecektir.',
  SECOND_ATTEMPT: 'İkinci deneme (1. hatırlatma)',
  THIRD_ATTEMPT: 'Üçüncü deneme (1. hatırlatma)',
  FINAL_ATTEMPT: 'Takipte kayboldum (kapat)',
  REMINDER_FREQUENCY: 'Hatırlatma sıklığı',
  AUTO_TRANSLATE_FOLLOW_UP_TEMPLATE:
    'Soru soran kişiyle iletişim kurmak için kullanılan dile otomatik çeviri uygulayın',
  ON_OFF_SWITCH: 'Açık kapalı',
  DAY: 'Gün',
  DAYS: 'Günler',
  MONTH: 'Ay',
  MONTHS: 'Aylar',
  RESET_TEMPLATES_TO_DEFAULTS: 'Şablonları varsayılanlara sıfırla',
  SAVE_FREQUENCY: 'Frekansı kaydet',
  SELECT_FREQUENCY: 'Frekansı seçin',
  TEMPLATE_MODIFICATIONS_HINT:
    'Metin manuel olarak değiştirildi ve artık otomatik olarak değiştirilmeyecek. Değişiklikleri iptal etmek ve otomatik güncellemeleri yeniden etkinleştirmek için burayı tıklayın.',
  LOGIN_INCORRECT_EMAIL_ADDRESS: 'Yanlış e-posta adresi!',
  AUDIT_TRAIL_FOR: '{{ ITEM }} için denetim takibi',
  AUDIT_TRAIL_FOR_ALL: 'Tüm {{ ITEM }} için denetim takibi',
  HISTORY_CHANGES_FOR: 'Şunun için geçmiş değişiklikleri:',
  HOLIDAY_GDPR_DISCLAIMER: 'Tatil GDPR sorumluluk reddi beyanı',
  MANAGE_HOLIDAY_GDPR_DISCLAIMER: 'Tatil GDPR sorumluluk reddi beyanını yönetin',
  MISSING_HOLIDAY_GDPR_TEMPLATE_FOR_TEAMS: 'Aşağıdaki ekip/ekipler için tatil GDPR şablonu eksik',
  BULK_CREATE: 'Toplu oluştur',
  LAST_SUCCESS: 'Son Başarı',
  LAST_FAILURE: 'Son Başarısızlık',
  FAILURE: 'Arıza',
  TASK: 'Görev',
  Weeks: 'Haftalar',
  REPEAT_EVERY: 'Her seferinde tekrarlayın',
  DEACTIVATE_ITEM: "{{ ITEM }}'i devre dışı bırak",
  RESET_FAILURE_COUNT: 'Arıza Sayısını Sıfırla',
  TASK_DIALOG_VALIDATION_MESSAGE_FOR_DUPLICATE:
    'Aynı değerlere sahip görevlerimiz olduğundan, lütfen iletişim kutusunun arkasındaki tabloda mevcut görevleri kontrol edin.',
  ACTIVATE_ITEM: "{{ ITEM }}'ı etkinleştir",
  PLEASE_ENTER_MIN_NUMBER: 'Lütfen {{ MIN_NUMBER }} değerinden büyük bir sayı girin.',
  MERGE_AS_NEW_INQUIRY: {
    DESCRIPTION_FOR_CLOSED_INTERACTION:
      'Birleştirme için seçilen hedef etkileşim zaten kapalı. Bu sorgulama yeni bir etkileşimdir ve birleştirilmesine gerek yoktur.',
    DESCRIPTION_FOR_OPEN_INTERACTION:
      'Mevcut sorgulamaya yeni bir soru/sorular eklenecektir, tüm soruların yanıtlanması gerekmektedir. Lütfen birleştirme için soruları seçin.',
    VALIDATION_MSG:
      'Birleştirme işlemine devam etmek için lütfen birleştirilmiş etkileşimden en az bir soru seçin.',
  },
  MERGED_INTERACTION: 'Birleştirilmiş etkileşim',
  TARGET_INTERACTION: 'Hedef etkileşim',
  MERGE_SUGGESTION: 'Öneriyi birleştir',
  PLEASE_REVIEW_MERGE_SUGGESTION_BELOW: 'Lütfen aşağıdaki birleştirme önerisini inceleyin',
  INQUIRERS_COMPARISON_TITLE:
    'Lütfen birleştirilmiş etkileşim ve hedef etkileşim için farklı sorgulayıcılara dikkat edin',
  INQUIRERS_COMPARISON_QUESTION:
    'Lütfen bu farklılığa rağmen devam etmek isteyip istemediğinizi onaylayın.',
  TERRITORY: 'Bölge',
  DOMAIN: 'İhtisas',
  IN: 'içinde',
  TEAM_GROUP_FILTER: {
    TITLE: 'Ek ekipleri görüntülemek için izinleri tanımlayın',
    SAVE_BTN_TOOLTIP: 'Lütfen takım için tüm zorunlu alanları girin ve önce takımı kaydedin.',
  },
  ADR_REF_ID: 'ADR Referans Kimliği',
  PQC_REF_ID: 'PQC Referans Kimliği',
  ATTACHMENT_PRESENT_IN_MULTIPLE_INTERACTIONS: 'Bu eklenti birden fazla etkileşimde mevcut',
  SELECT_CHECKBOX_TO_DELETE_ATTACHMENT_FROM_ALL_INTERACTIONS:
    'Eki tüm etkileşimlerden silmek istiyorsanız lütfen onay kutusu seçeneğini seçin.',
  CONFIRM_DELETE_ATTACHMENT: 'Lütfen bu eki silmek isteyip istemediğinizi onaylayın.',
  DELETE_FROM_ALL_INTERACTIONS_MENTIONED_ABOVE: 'Yukarıda belirtilen tüm etkileşimlerden silin',
  ATTACHMENT_DELETED: 'Ek silindi',
  ROLES: 'Roller',
  SET_TO_ALL_INQ_TOOLTIP:
    'Tüm sorular için aynı ADR, PQC ve Etiket Dışı karakterizasyonunu ayarlayın.',
  TASKS_LIST: 'Görev Listesi',
  TASK_LOGS: 'Görev Günlükleri',
  SELECTED: 'Seçildi',
  LICENSE_COUNTER: 'Lisans sayacı',
  LICENSES_LIMIT: 'Lisans numarası sınırı',
  USERS_TO_EXCLUDE: 'Hariç tutulacak kullanıcılar',
  ENTER_MAX_NUMBER_OF_LICENSES: 'Sözleşmeli lisans ücretleri (kullanıcı rolleri).',
  SELECT_USER_ROLES_TO_COUNT: 'Lisans ücretlerinin sayımına uygun kullanıcı rollerini seçin.',
  SELECT_USERS_TO_EXCLUDE_FROM_COUNT: 'Kullanıcıları lisans ücreti sayısından hariç tutun.',
  RECEIPT_AND_SALES_NOTIFICATIONS: 'Makbuz ve Satış bildirimleri',
  REFRESH_MIGRATION_ERRORS: 'Taşıma Hataları listesini yenileyin',
  MARKED_AS_RESOLVED_BY: 'Tarafından çözümlendi olarak işaretlendi',
  MARKED_AS_RESOLVED_ON: 'Çözümlendi olarak işaretlendi',
  MARK_AS_RESOLVED: 'Çözüldü olarak işaretle',
  ERROR_ON: 'Hata açık',
  TEXT_WITH_SPECIAL_CHARS:
    'Lütfen yalnızca harfleri (a-z, A-Z), sayıları (0-9) ve tire (-), nokta (.), boşluk, iki nokta üst üste (:), virgül (,), parantez (()), ileri gibi özel simgeleri girin eğik çizgi (/) ve artı (+).',
  STATUS_DROPDOWN_TOOLTIP_TEXT_FOR_IN_APPROVAL:
    'Onay için gönderildi durumundaki etkileşim. Bu menüyü etkinleştirmek için lütfen onaylayın/reddedin.',
  REFRESH_TASK_LOGS: 'Görev Günlükleri listesini yenile',
  LICENSE_COUNTER_ACTION_EDIT_CONFIRM_TITLE:
    'Değişikliklere devam etmek istiyorsanız lütfen onaylayın!',
  DEPUTY_ROLE_ARCHIVE_NOT_ALLOWED: 'Vekalet edilen roller için manuel arşivlemeye izin verilmez.',
  RUN_COUNTER: 'Koşu sayacı',
  EXPORT_LICENSES: 'Lisansları dışa aktar',
  LICENSE_COUNT_MESSAGE: 'Kullanılan lisans sayısı:',
  QUESTIONS: 'Sorular',
  CLARIFICATION: 'Açıklama',
  MERGE_AS_INQUIRY: {
    VALIDATION_MSG: 'Birleştirmeye devam etmek için lütfen hedef etkileşimde en az bir soru seçin.',
    DESCRIPTION: 'Açıklama sorusu, hedef etkileşimde seçilen soru(lar)ın altına eklenecektir.',
  },
  MERGE_AS_DUPLICATE: {
    DESCRIPTION:
      'Etkileşimi mevcut etkileşimin kopyası olarak işaretlemek için bu seçeneği belirleyin.',
  },
  DETECTED_CLARIFICATION_QUESTION_WITHOUT_ANSWER:
    'Açıklama sorusu olarak önceki birleştirme algılandı. Hedef etkileşimde önceden seçilen yanıt alanlarına ek olarak seçilen tüm yanıt alanları yanıt için yeniden açılacaktır.',
  DISABLED_ANSWER_FIELD_TOOLTIP:
    'Başka bir sorunun cevabını sağlamak için etkileşim yeniden açıldı. Bu sorunun cevabı daha önce verilmişti.',
  TIP: 'Uç',
  MERGE_TIP_TEXT:
    'Bunun yerine {{ MERGED_INTO_ID }} etkileşimini yeniden açmak istiyorsanız, lütfen hayır tuşuna basın ve {{ MERGED_INTO_ID }} hedef etkileşimini belirten yeni bir birleştirme işlemi başlatın.',
  YOU_ARE_SELECTING_MERGE_WITHOUT_REOPEN:
    'Hedef etkileşimi açmadan bir birleştirme işlemi seçiyorsunuz.',
  YOU_ARE_SELECTING_MERGE_INTO_INVALID_INTERACTION:
    'Geçersiz bir hedef etkileşime ({ TARGET_INTERACTION_ID }} yönelik bir birleştirme işlemi seçiyorsunuz.<br>Etkileşim {{ TARGET_INTERACTION_ID }}, {{ STATUS }}{{ LAST_MERGE_INFO }} durumuyla kapatıldı.',
  CLOSED_INTERACTION_AND_INABILITY_TO_PROVIDE_ANSWER:
    'Bu, kapalı etkileşime ve yanıt verememeye neden olacaktır.',
  INTO_INTERACTION: 'etkileşime girmek',
  PLEASE_SELECT_INTERACTION_FOR_MERGE: 'Lütfen birleştirme için etkileşimi seçin.',
  PLEASE_SELECT_MERGE_REASON: 'Lütfen birleştirme nedenini seçin.',
  QUESTION_CANNOT_BE_DELETED:
    'İlgili yanıtlanmamış bir açıklama sorusu olduğundan bu soru silinemez.',
  MERGE_REASON: 'Birleştirme nedeni',
  WITH_REOPEN: 'yeniden açma ile',
  MERGED_INTO_QUESTION_IDS: 'soru kimlik(ler)iyle birleştirildi',
  MERGED_INTO_QUESTIONS: 'soru(lar)la birleştirildi',
  MERGED_PREVIOUSLY_FROM: 'daha önce birleştirilmiş',
  FROM_INTERACTION_ID: 'etkileşim kimliğinden',
  IN_INTERACTION_ID: 'etkileşim kimliğinde',
  YOU_ARE_ABOUT_TO_CANCEL_MERGE_SUGGESTION: 'Birleştirme önerisini iptal etmek üzeresiniz.',
  DO_NOT_SHOW_MERGE_SUGGESTION_AGAIN: 'Birleştirme önerisi tekrar gösterilmiyor mu?',
  PRODUCT_RESOLVED_VIA_AI: 'Ürün (Yapay Zeka aracılığıyla çözüldü)',
  REQUIRED_FIELDS_HINT: 'Lütfen tüm gerekli alanları doldurunuz',
  AT_LEAST_ONE_CONTACT_IS_REQUIRED: 'En az bir kişi gerekli!',
  DISCLAIMER_INFO: 'Sorumluluk reddi beyanı bilgisi',
  QUESTION_ID: 'Soru Kimliği',
  QUESTION_IDS: 'Soru kimlikleri',
  SHOW_TEAMS: 'Takımları Göster',
  LAST_POLLING: 'Son Oylama',
  HOST: 'Ev sahibi',
  PROTOCOL: 'Protokol',
  FROM_EMAIL: 'E-postadan',
  REASON_FOR_REMOVAL: 'Kaldırılma Nedeni',
  A_CLOSED_INTERACTION_IS_NOT_EDITABLE: 'Kapalı bir etkileşim düzenlenemez.',
  METADATA_DROPDOWNS_TOOLTIP_TEXT:
    'Etkileşimin birden fazla sorusu vardır. Düzenleme yalnızca açık etkileşimle mümkündür.',
  BULK_EDIT: 'Toplu düzenleme',
  HOW_TO_ENABLE_BULK_EDIT:
    'Toplu güncelleme yapılabilmesi için aynı dilde en az iki şablonun seçilmesi gerekir.',
  REASON_FOR_UPDATE: 'Güncelleme nedeni',
  FOLLOW_UP_RESEND: 'Takip yeniden gönderildi',
  SENT_FOLLOW_UP_REMINDER: 'takip hatırlatıcısı gönderildi',
  CLOSED_INTERACTION_AND_SENT_LOST_TO_FOLLOW_UP_NOTIFICATION:
    'etkileşimi kapattı ve takip bildirimine kayboldu',
  ENTER_TEXT_WITH_MIN_LEN_15_CHARS: 'Lütfen minimum 15 karakter uzunluğunda metin giriniz.',
  FOLLOW_UP_DISABLED_INSERT_ENQUIRER_FIRST:
    'Takip devre dışı bırakıldı! Lütfen önce sorgulayıcıyı ekleyin.',
  FOLLOW_UP_REQUEST_DISABLED_INSERT_ENQUIRER_EMAIL_FIRST:
    'Takip isteği devre dışı bırakıldı! Lütfen önce geçerli sorgulayıcının e-posta adresini girin.',
  FOLLOW_UP_FREQUENCY_VALIDATION_MSG:
    'Seçilen frekans türüne ilişkin sayı 1 ile {{ MAX_ALLOWED_FREQUENCY }} arasında olmalıdır.',
  FOLLOW_UP_PLEASE_SELECT_FREQUENCY_TYPE: 'Lütfen frekans tipini seçiniz.',
  FOLLOW_UP_FREQUENCY_TOOLTIP:
    'Sayım, ilk takip talebinin tarih ve saatinden itibaren başlar. Günleri haftalara dönüştürmek için 7 ile çarpın; 2 hafta = 14 gün, günler ile aylar arasında 30 ile çarpın, ör. 1 ay = 30 gün.',
  VALID_PHONE_FAX: 'Lütfen geçerli bir telefon/faks numarası giriniz!',
  SELECT_LANG_TO_VIEW_AND_MANAGE_TRANSLATED_TEMPLATES:
    'Çevrilen şablonları görüntülemek ve yönetmek için bir dil seçin',
  RETRANSLATE: 'Yeniden çevir',
  FOLLOW_UP_REMINDERS_INFO_TEXT:
    'Aşağıdaki şablonlar yalnızca İngilizce dilindedir; çeviriler aşağıda yönetilmektedir',
  FOLLOW_UP_RESET_BTN_TOOLTIP:
    'Yukarıdaki tüm şablonlar varsayılanlara sıfırlanacak (manuel değişiklik kaldırılacak, sıklık 14 güne sıfırlanacak ve çeviri onay kutusu seçimi kaldırılacak), tüm dillere yeniden çevrilecek ve is_manually_modified seçeneği silinecek.',
  FOLLOW_UP_EDIT_BTN_TOOLTIP:
    'Şablon metnini düzenleyin ve manuel olarak değiştirilmeyen tüm çevirileri yeniden çevirin.',
  FOLLOW_UP_RETRANSLATE_BTN_TOOLTIP:
    'Elle değiştirilenler de dahil olmak üzere orijinal şablon metnini yeniden çevirin.',
  FOLLOW_UP_REMINDER_TEMPLATES: 'Takip hatırlatma şablonları',
  MANUALLY_MODIFIED: 'Manuel olarak değiştirildi',
  SYNC: 'Senkronizasyon',
  DIFFERENT: 'Farklı',
  DEFAULT_CONFIRMATION_QUESTION: 'Devam etmek isteyip istemediğinizi lütfen onaylayın.',
  SEND_WELCOME_MAIL_: 'Şifre sıfırlamayı içeren hoş geldiniz e-postası gönderin',
  CONFIRM_FU_RESPONSE_WITH_REOPEN:
    'Soruşturmacıya yeni bir takip bilgisi ulaştığını teyit ediyor ve yanıt sağlanması için etkileşimi yeniden açmak istiyorum.',
  ONLY_LETTERS_SPACE_AND_DASH: 'Lütfen sadece harf, boşluk ve - sembolünü girin!',
  INFO: 'Bilgi',
  INQUIRER_INTERACTION_COUNTRY_CHANGE_CONFIRM_MSG:
    'Sorgulayıcının ülkesindeki değişiklik, etkileşimin atamasını {{ CURRENT_TEAM }} ekibinden {{ FUTURE_TEAM }} ekibine değiştirecek.',
  INQUIRER_INTERACTION_COUNTRY_CHANGE_CONFIRM_MSG_REMOVE_ASSIGNMENT:
    'Sorgulayan ülkedeki değişiklik, etkileşimin atamasını {{ CURRENT_TEAM }} ekibinden {{ FUTURE_TEAM }} ekibine değiştirecektir. {{ ASSIGNED_TO }} adlı kullanıcının {{ FUTURE_TEAM }} ekibi için izinleri yoktur ve kişisel atamaları kaldırılacaktır.',
  INQUIRER_INTERACTION_COUNTRY_CHANGE_CONFIRM_MSG_FOR_RELATED_INTERACTIONS:
    'Değişiklik, kişisel atamaların kaldırılacağı aşağıdaki ek etkileşimlerdeki ekip atamalarını etkileyecektir: {{ RELATED_INTERACTIONS_TEAMS_AND_USERS }}',
  INQUIRER_HCP_COUNTRY_CHANGE_CONFIRM_MSG_FOR_RELATED_INTERACTIONS:
    'Soru soran ülkedeki değişiklik, aşağıdaki ek etkileşimlerde ekip atamalarını değiştirecek ve kişisel atama kaldırılacaktır. {{ RELATED_INTERACTIONS_TEAMS_AND_USERS }}',
  INQUIRER_INTERACTION_COUNTRY_CHANGE_RELATED_INTERACTIONS_DATA:
    "Etkileşim Kimliği: {{ INTERACTION_ID }}, {{ CURRENT_TEAM }}'den {{ FUTURE_TEAM }}'e, {{ ASSIGNED_TO }} kullanıcısından kişisel atama kaldırıldı",
  INQUIRER_INTERACTION_COUNTRY_CHANGE_INFO_MSG_FOR_RELATED_INTERACTIONS:
    '{{ EDITING_USER }} adlı kullanıcı, etkileşim kimliğinde {{ UPDATED_INTERACTION_ID }} olan sorgulayıcı {{ ENQUIRER }} ülkesini değiştirdi; bu da mevcut etkileşimin atamasını {{ OLD_TEAM }} takımından {{ NEW_TEAM }} takımına değiştirdi.',
  INQUIRER_HCP_COUNTRY_CHANGE_INFO_MSG_FOR_RELATED_INTERACTIONS:
    '{{ EDITING_USER }} adlı kullanıcı, HCP yönetiminde sorgulayıcı {{ ENQUIRER }} ülkesini değiştirdi; bu da mevcut etkileşimin atamasını {{ OLD_TEAM }} ekibinden {{ NEW_TEAM }} ekibine değiştirdi.',
  INQUIRER_COUNTRY_CHANGE_ADDITIONAL_INFO_MSG_FOR_RELATED_INTERACTIONS:
    '{{ INTERACTION_ASSIGN_TO }} adlı kullanıcının {{ NEW_TEAM }} takımı için izinleri yok ve kişisel atamaları kaldırıldı.',
  PERSONAL_ASSIGNMENT: 'Kişisel ödev',
  DUE_TO_FU_REQUEST: 'takip talebi nedeniyle',
  DUE_TO_FU_RESPONSE: 'takip yanıtı nedeniyle',
  DUE_TO_FU_REMINDERS_FREQ_UPDATE:
    'yönetim sayfasından takip hatırlatmalarının sıklığının güncellenmesi nedeniyle',
  UPDATED_FROM_INBOX: 'gelen kutusundan güncellendi',
  ARCHIVED_ROLE: 'Arşivlenmiş rol',
  USER_ROLE_ARCHIVE_CONFIRM_MSG_INTERACTIONS_DATA:
    'Kullanıcı rolünün arşivlenmesi, kişisel atamaların kaldırılacağı aşağıdaki ek etkileşimlerdeki atamaları değiştirecektir: {{ USER_INTERACTIONS }}',
  USER_ROLE_ARCHIVE_INFO_MSG_FOR_RELATED_INTERACTIONS:
    '{{ EDITING_USER }} adlı kullanıcı, {{ INTERACTION_ASSIGN_TO }} kullanıcısını düzenleyerek, {{ TEAM }} ekibindeki rolünü {{ USER_ROLE }} olarak arşivledi. {{ INTERACTION_ASSIGN_TO }} adlı kullanıcının {{ TEAM }} ekibi için izinleri yok ve kişisel ataması kaldırıldı.',
  ASSIGNMENT: 'Atama',
  PERIOD_TO_PERIOD_OVERLAP: 'Dönem ve Döneme kadar örtüşüyor',
  PERIOD_TO_PERIOD_MISMATCH:
    'Dönem ve Bitiş Dönemi farklı sürelere sahiptir - {{ PERIOD }} {{ INTERVAL }} ve {{ TO_PERIOD }} {{ TO_INTERVAL }}',
  CREATED_QUESTION_FROM_FU_RESPONSE: 'takip eden yanıttan soru oluşturuldu',
  CREATE_QUESTION_FROM_FU_RESPONSE_TOOLTIP:
    'Bu seçenek, takip edilen bir sorudan yeni bir soru oluşturulmasına olanak tanır.',
  ADD_AS_A_NEW_QUESTION: 'Yeni bir soru olarak ekle',
  ROLE_ARCHIVE_WITH_USER_DEACTIVATION:
    "Lütfen bu kullanıcı için son onaylanan rolü arşivlemeye çalıştığınızı ve bunun kullanıcının devre dışı bırakılmasına ve EnqMed'e daha fazla erişiminin engellenmesine neden olacağını unutmayın.",
  ATTEMPTED_FOLLOW_UP: 'bir takip girişiminde bulunuldu',
  FAILED_FU_ATTEMPT: 'Başarısız takip girişimi',
  UNABLE_TO_REACH_ENQUIRER: 'Soruyu soran kişiye ulaşılamadı.',
  FAILED_FU_ATTEMPT_TOOLTIP:
    'Sorgulayan kişiye ulaşma girişimi yapılmış ancak başarılı olmamışsa, örneğin telefonla ulaşılamamışsa, yüz yüze görüşme veya başka bir randevu başarısız olmuşsa bu seçeneği seçin.\nGirişim etkileşim geçmişine kaydedilecektir.',
  REDIRECT_TO_PAGE_WITH_PERMISSION_ACCESS:
    'İzinlerine sahip olduğunuz bir sayfaya yönlendirileceksiniz.',
  FU_STOPPED_DUE_TO_ENQ_CHANGE:
    'Sorgulayıcının değiştirilmesi sonucu takip sürecinin durdurulması nedeniyle',
  YOU_ARE_ABOUT_TO_SWITCH_ENQ_AND_STOP_FU:
    'Sorgulayan kişiyi değiştirmek üzeresiniz, bu da takip sürecini durduracaktır.',
  INQUIRER_DETAILS: 'Sorgulayıcı Ayrıntıları',
  INQUIRER_TYPE: 'Sorgulayıcı Türü',
  DELETE_INQUIRER_PII: 'Sorgulayanın kişisel bilgilerini sil',
  MARKED_INQUIRER_PII_DELETE: 'İşaretli sorgulayıcı silinmek üzere kişisel bilgiler',
  UNMARKED_INQUIRER_PII_DELETE: 'işaretlenmemiş sorgulayıcının silinmesi için kişisel bilgiler',
  NEW_ENQUIRER: 'Yeni araştırmacı',
  NEW_ENQUIRER_TOOLTIP:
    'Tamamen yeni bir sorgulayıcı oluşturmak için bu seçeneği kullanın. Bunun yerine mevcut sorgulayıcı verilerinin düzenlenmesi gerekiyorsa bu seçeneği kullanmayın.',
  CONFIRM_SET_NEW_ENQUIRER:
    "Lütfen mevcut sorgulayıcıyı yenisiyle değiştirmek istediğinizi onaylayın.<br>Girilen tüm bilgiler kaybolacaktır.<br>Bunun yerine mevcut sorgulayıcının verilerini düzenlemek istiyorsanız, verileri doğrudan formda değiştirin ve Kaydet'e basın.",
  INQUIRER: 'Soruşturmacı',
  KEEP_INQUIRER_DETAILS_OPEN: 'Sorgulayıcı Ayrıntılarını Açık Tutun',
  LOST_UNSAVED_CHANGES_ON_INTERACTION_CLOSE_MESSAGE:
    'Sorgulayan kişinin ayrıntılarını kapatırsanız kaybolacak olan kaydedilmemiş değişiklikleriniz var.',
  SHARE_FROM_HERE_YOU_CAN_:
    'Paylaş: Buradan sorgulayan kişiye bilgi atayabilir, iletebilir veya takip başlatabilirsiniz.',
  UPDATED_INQUIRER_DETAILS: 'güncellenmiş sorgulayıcı detayları',
  CREATED_INQUIRER: 'yaratılmış sorgulayıcı',
  SUGGESTED_INQ: 'Önerilen Soruşturmacılar',
  SELECT_INQUIRER: "Enquirer'ı seçin",
  ADR_OBLIGATION_CONSENT_AND_INQUIRER_:
    'Etkileşimi sunmak için ADR yükümlülüğü onayı ve sorgulayıcı seçilmelidir.',
  ANSWER_HAS_NOT_BEEN_SENT_TO_THE_INQ_:
    'Yanlış e-posta adresi nedeniyle soru soran kişiye cevap gönderilemedi.',
  MSG_NOTIFICATION_WILL_BE_SEND_1:
    'Teşekkür ederim! Bildirim, talep gönderildiğinde veya cevaplandığında gönderilecektir.',
  MSG_NOTIFICATION_WILL_BE_SEND_2:
    'Teşekkürler! Sorgulayan kaydedildiğinde bildirim gönderilecektir.',
  NOTIFICATION_WILL_BE_SENT_TO_THE_INQ:
    'Aşağıdaki GDPR bildirimi, talep eden kişiye e-posta yoluyla gönderilecektir.',
  NOTIFICATION_SENT_TO_THE_INQ:
    'Aşağıdaki GDPR bildirimi, talep sahibine e-posta yoluyla gönderilmiştir.',
  SEND_A_NOTIFICATION_TO_THE_INQ:
    'Aşağıdaki GDPR bildirimini talep eden kişiye e-posta yoluyla gönderin.',
  INQ_HAS_NO_EMAIL: 'Soruşturmacının e-postası yok.',
  A_NOTIFICATION_HAS_NOT_BEEN_TO_INQ:
    'Soruşturmayı yapan kişiye, talebin alındığına dair bir bildirim GÖNDERİLMEMİŞTİR.',
  NOTIFICATION_SENT_TO_THE_INQUIRER:
    'Soruşturmayı yapan kişiye, soruşturmanın alındığına dair bildirim gönderilir.',
  THIS_IS_JUST_A_THANK_YOU: 'Bu, mevcut soruşturmayı yapan kişiden gelen bir teşekkür mesajıdır.',
  SAVE_INQUIRER: "Enquirer'ı kaydet",
  INQ_DEFAULT_VAL: 'Enquirer varsayılan değerleri',
  COMPANY_EMPLOYEE_SUBMISSION_TOOLTIP:
    'Şirket temsilcisi (satış temsilcisi, MSL veya diğer) aracılığıyla iletilen sorular için, sorgulayan kişiye kişisel verilerinin işlenmesi hakkında bilgi verin.',
  SELECT_PREFERRED_CONTACT_ERROR:
    'Sorgulayan kişi için tercih edilen bir irtibat kişisi seçmelisiniz.',
  FOLLOW_UP_INITIATED_BY_INQUIRER: 'Soruşturmacı tarafından başlatılan takip',
  NO_INQUIRER: 'Hiçbir araştırmacı',
  'Changed inquirer': 'Değişen sorgulayıcı',
  DELETE_INQUIRY_MESSAGE: 'Bu sorguyu silmek üzeresiniz. Bu eylem geri alınamaz.',
  QUESTION_REQUIRE_ANSWER_MESSAGE:
    'Soruşturmayı kapatmak için yeni soruya cevap verilmesi gerekecektir.',
  IMAGE: 'Resim',
  THERAPEUTIC_AREA_STATUS_MESSAGE: 'Terapötik Alan {{ STATUS }}',
  THERAPEUTIC_AREA_SELECT_PRODUCT_TO_ACTIVATE:
    ', ilişkili ürün bulunmadığından. Terapötik alanı aktif hale getirmek için lütfen en az bir ürün seçin.',
  EXPAND_ALL_THERAPEUTIC_AREAS: 'Tüm terapötik alanları genişletin',
  COLLAPSE_ALL_THERAPEUTIC_AREAS: 'Tüm terapötik alanları daralt',
  THERAPEUTIC_AREA_EDIT_PARENT_HINT:
    "Bir Terapötik Alanı (TA) ana TA olarak ayarlamak için, ana TA'yı seçimini kaldırın ve kaydedin.",
  EXTRA_STEP_REQUIRED_TO_LOGIN: 'Giriş yapmak için ekstra bir adım gerekiyor',
  EXTRA_STEP_DESCRIPTION:
    'Giriş onayı e-postanıza gönderildi. Lütfen sisteme devam etmek için içindeki adımları takip edin.',
  ENTER_TEXT_WITH_MIN_LEN_15_CHARS_OR_ATTACHMENT:
    'Lütfen en az 15 karakter uzunluğunda bir metin veya ek girin.',
  REASON_FOR_REJECTION: 'Reddedilme nedeni',
  NOTE_PROPERLY_INSERTED_IN_HISTORY: 'Not geçmişe düzgün bir şekilde eklendi.',
  METADATA_ACTION_EDIT_CONFIRM_TITLE:
    "Seçili {{ ACTION_TYPE }}'ın {{ METADATA_TYPE }} olarak değiştirilmesiyle, aşağıdaki ek ilgili değişiklikler uygulanacaktır:",
  THERAPEUTIC_AREA_ACTION_EDIT_CONFIRM_TITLE:
    'Ürünlerin kaldırılmasıyla birlikte aşağıdaki ek ilgili değişiklikler geçerli olacaktır:',
  TEAM_NOTIFY_INQUIRER: 'Sorgulayana haber ver',
  TEAM_NOTIFY_INQUIRER_TOOLTIP:
    'Bu seçeneğin etkinleştirilmesi, bu ekipte henüz bilgilendirilmemiş açık etkileşimlerdeki sorgulayanlara otomatik olarak GDPR bildirimleri gönderecektir.',
  TEAM_NOTIFY_SALES: 'Satışları bildir',
  TEAM_NOTIFY_SALES_TOOLTIP:
    'Bu seçeneği etkinleştirmek, bu takımda satış rolüne sahip tüm kullanıcılar için cevap içeren bildirimi etkinleştirecektir.\n\nBu seçeneği devre dışı bırakmak, bu takımda satış rolüne sahip tüm kullanıcılar için cevap içeren bildirimi devre dışı bırakacaktır, ancak başka bir takımda satış rolüne sahip ve satışları bildir ayarı etkinleştirilmişse.',
  RECEIVES_THE_FOLLOWING_NOTIFICATION: 'aşağıdaki bildirimi alır',
  EMAILS: 'E-postalar',
  PHONES: 'Telefonlar',
  USERS_DEACTIVATED_SUCCESSFULLY: 'Kullanıcı/Kullanıcılar başarıyla devre dışı bırakıldı',
  CONFIRM_BULK_DEACTIVATION_OF_USERS:
    'Kullanıcıların toplu olarak devre dışı bırakılmasını onaylayın',
  DEACTIVATE_SELECTED_USERS: 'Seçili kullanıcıları devre dışı bırak',
  SELECT_USERS_TO_DEACTIVATE: 'Devre dışı bırakılacak kullanıcıları seçin',
  ARCHIVE_FOLDER: 'Arşiv klasörü',
  EXTRA_PARAMS: "Ek parametreler (k1=v1&k2=v2, 'klasörler' ve 'arşiv' ayrılmıştır ve kullanılamaz)",
  POLL_FOLDER: 'Anket klasörü',
  POLL_FOLDERS_TEXT:
    'Anket klasörleri yalnızca imap veya gmail protokolleri için ayarlanabilir. Diğer tüm protokoller için varsayılan gelen kutusudur.',
  POLL_FOLDERS_TOOLTIP_1:
    "Klasör adını yazın ve Enter'a basın veya posta kutusundan yoklama için eklemek üzere virgül kullanın (Klasör eklenmezse, varsayılan olarak Gelen Kutusu yoklanır).",
  POLL_FOLDERS_TOOLTIP_2: 'Burada listelenen klasör adları posta kutusundan sorgulanacaktır.',
  PERMISSION: 'İzin',
  ROLE_DIALOG_VALIDATION_MESSAGE_FOR_DUPLICATE:
    'Lütfen diyalog kutusunun arkasındaki tabloda mevcut rolleri kontrol edin, çünkü aynı isimde rolümüz zaten var.',
  CURR_MAILBOX: 'Mevcut posta kutusu',
  ENQUIRY_ANSWER_APPROVAL: 'Sorgulama cevap onayı',
  REASON_FOR_INCLUSION: 'Dahil edilme nedeni',
  FOLDERS_TO_POLL_FROM_MAILBOX: 'Posta kutusundan yoklanacak klasörler',
  VERIFY_SETUP: 'Kurulumu doğrulayın',
  HTML_PREVIEW: 'HTML Önizleme',
  IN_REPLY_TO_ID: "id'ye yanıt olarak",
  INCOMING: 'Gelen',
  OUTGOING: 'Dışa dönük',
  VIEW: 'Görüş',
  ROLE_IN_USE_USERS:
    'Rol şu anda kullanıcılara atanmış durumda ve arşivlenmesi erişim haklarını sınırlayabilir',
  ROLE_IN_USE_USER_TEMPLATES:
    'Rol şu anda kullanıcı şablonları tarafından kullanılıyor ve arşivlenmesi onu onlardan kaldıracak',
  ROLE_IN_USE_LICENSE_COUNTER:
    'Rol şu anda lisans sayacında kullanılıyor ve arşivlendiğinde buradan kaldırılacak.',
  REASON_FOR_REPLACEMENT: 'Değiştirme nedeni',
  QUESTION_TEXT_AFTER_SPLIT: 'Bölmeden sonra soru metni',
  TEAM_ENQUIRY_ANSWER_APPROVAL_TOOLTIP:
    'Sorgulama yanıt onayı iş akışı ekip için devre dışı bırakılacak. "Onay İçin Gönderildi" durumundaki etkileşim/etkileşimler etkilenecek ve onay olmadan doğru yanıt hazırlanmasına ve yanıtlanmasına izin vermek için "Devam Ediyor" durumuna geri döndürülecek.',
  REVERT_TO_IN_PROGRESS: "DEVAM EDİYOR'A GERİ DÖN",
  SPLIT_IN_INTERACTION_IN_APPROVAL_STATE_MESSAGE:
    'Soru bölünmesi, etkileşim {{ SUBMITTED_FOR_APPROVAL }} durumundayken gerçekleştirildi. Bu eylem, yeni oluşturulan soru/sorular için cevap hazırlanmasına izin vermek üzere durumu {{ IN_PROGRESS }} durumuna geri döndürür.',
  MERGE_IN_INTERACTION_IN_APPROVAL_STATE_MESSAGE:
    'Hedef etkileşim {{ MERGE_AS_ADDITIONAL_QUESTION }} durumundayken, geçerli etkileşime {{ SUBMITTED_FOR_APPROVAL }} eylemi gerçekleştirildi. Bu eylem, yeni oluşturulan soru/sorular için yanıt hazırlanmasına izin vermek üzere durumu {{ IN_PROGRESS }} durumuna geri döndürür.',
  ENQUIRY_ANSWER_APPROVAL_TITLE: 'Seçilen ekipler için sorgu yanıt onayını ayarlayın',
};
