import { Component, Inject, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { BInteraction } from 'app/modules/data-model/interaction/interaction';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SingleInteractionService } from 'app/modules/data-model/interaction/interaction.service';
import { InquiriesService } from 'app/modules/inquiries/services/inquiries.service';

type ModalInquiryData = { id: number; interaction: BInteraction; hideActions: boolean };

@Component({
  selector: 'app-modal-inquiry',
  templateUrl: './modal-inquiry.component.html',
  styleUrls: ['./modal-inquiry.component.scss'],
})
export class NgbdModalInquiry implements OnDestroy {
  interaction: BInteraction;
  hideActions = false;
  isLoading = true;
  isPermissionDenied = false;
  reassignToDropdownTooltipText: string = null;

  private subs = new Subscription();

  constructor(
    @Inject(MAT_DIALOG_DATA) data: ModalInquiryData,
    private singleInteractionService: SingleInteractionService,
    private inquiriesService: InquiriesService
  ) {
    this.hideActions = data.hideActions;
    if (data.interaction) {
      this.interaction = data.interaction;
      this.isLoading = false;
    } else {
      this.subs.add(
        this.singleInteractionService.permissionForRelatedInteractionDenied$.subscribe(() => {
          this.isPermissionDenied = true;
          this.isLoading = false;
        })
      );
      this.subs.add(
        this.singleInteractionService.relatedContentInteractionResult.subscribe((interaction) => {
          this.interaction = interaction;
          this.reassignToDropdownTooltipText = this.inquiriesService.getInlineEditingTooltipText([
            interaction,
          ]);
          this.isLoading = false;
        })
      );
      this.singleInteractionService.performRelatedInteractionQueryWithId(data.id, true);
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  onReassign(): void {
    this.inquiriesService.reloadTheDataTable$$.next(true);
  }
}
